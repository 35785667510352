// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tag-picker {
  width: 400px;
  margin: 5px;
  border: solid #000000 1px;
  border-radius: 5px;
}

.custom-tag-picker * {
    color: #1a6a7c !important;
}

.custom-tag-picker span {
  font-size: 20px;
}

.custom-tag-picker button {
  top: 0;
  bottom: 0;
}

`, "",{"version":3,"sources":["webpack://./src/Components/TagPicker.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,MAAM;EACN,SAAS;AACX","sourcesContent":[".custom-tag-picker {\n  width: 400px;\n  margin: 5px;\n  border: solid #000000 1px;\n  border-radius: 5px;\n}\n\n.custom-tag-picker * {\n    color: #1a6a7c !important;\n}\n\n.custom-tag-picker span {\n  font-size: 20px;\n}\n\n.custom-tag-picker button {\n  top: 0;\n  bottom: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
