import styled from "styled-components";
export const StyledLogo = styled.div`
  text-align: center;
`;

export const Logo: React.FC<{}> = () => {
  return (
    <StyledLogo>
      <img src="/img/kotobot.svg" />
    </StyledLogo>
  );
};
