import { observer } from "mobx-react";
import { SaveStoreApi } from "../../Stores/API/SaveStore";
import { StyledTitleH2 } from "../../Components/StyledComponents/Text";
import { StyledFlexConteiner } from "../../Components/StyledComponents/Containers";
import { StyledDefaultButton } from "../../Components/StyledComponents/Buttons";
import { useNavigate } from "react-router-dom";

export let SaveWindowTitle: React.FC<{ store: SaveStoreApi }> = observer(
  ({ store }) => {
    console.log("render title");
    return <StyledTitleH2>{store.saveWindowTitle}</StyledTitleH2>;
  }
);

export let SaveWindowButton: React.FC<{ store: SaveStoreApi }> = observer(
  ({ store }) => {
    console.log("render button");
    let navigate = useNavigate();

    return (
      <StyledFlexConteiner margin="30px">
        <StyledDefaultButton
          onClick={async () => {
            let id = await store.saveClick;
            if (id !== null && id !== undefined) {
              store.setId(id);
              navigate("../update/" + id, { replace: true, relative: "path" });
            }
          }}
        >
          {store.saveButtonText}
        </StyledDefaultButton>
      </StyledFlexConteiner>
    );
  }
);
