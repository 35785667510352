import { useLoaderData } from "react-router-dom";
import {
  StyledFileInput,
  StyledInputWithBorderWithGrow,
} from "../../../Components/StyledComponents/Input";
import { StyledTextArea } from "../../../Components/StyledComponents/TextArea";
import {
  StyledConteiner,
  StyledFlexConteiner,
  StyledInputConteiner,
} from "../../../Components/StyledComponents/Containers";
import { StyledLabel } from "../../../Components/StyledComponents/Label";
import { observer } from "mobx-react";
import { StyledFullImg } from "../../../Components/StyledComponents/Image";
import React from "react";
import {
  StartTaskData,
  TaskShowDataFromResponse,
  TaskShowApi,
} from "../../../DTO/ServerResponseType/Task";
import { TaskStore } from "../../../Stores/TaskStore";
import { StyledSelect } from "../../../Components/StyledComponents/Select";
import { SaveWindowButton, SaveWindowTitle } from "../SaveWindowComponents";
import dictionary from "../../../langs.json";
import { TagPicker } from "rsuite";
import "../../../Components/TagPicker.css";
import { StyledPrimaryRightMarginButton } from "../../../Components/StyledComponents/Buttons";
import TagStoreObj, { TagStore } from "../../../Stores/TagStore";
import "../CreateWindow.css"
import { Type } from "../../../DTO/ServerResponseType/Type";

let TaskImage: React.FC<{ taskStore: TaskStore }> = observer(
  ({ taskStore }) => {
    console.log("render image");
    return (
      <>
        {taskStore.taskIllustration && (
          <StyledFlexConteiner>
            <StyledConteiner height="637px" width="637px">
              <StyledFullImg src={taskStore.taskIllustration.src} />
            </StyledConteiner>
          </StyledFlexConteiner>
        )}
      </>
    );
  }
);

let TagsPicker: React.FC<{
  tagStore: TagStore;
  taskStore: TaskStore;
  startValue?: string[];
}> = observer(({ tagStore, taskStore, startValue }) => {
  console.log("render tag picker");
  return (
    <StyledInputConteiner>
      <StyledLabel>Теги</StyledLabel>
      <TagPicker
        className="custom-tag-picker"
        locale={{
          placeholder: "Выберите теги",
          noResultsText: "Теги не найдены",
        }}
        data={tagStore.tags.map((item) => ({
          label: item.tagName,
          value: item.tagId,
        }))}
        onChange={(value) => {
          taskStore.onChangeTags(value);
        }}
        defaultValue={startValue}
      />
      <StyledPrimaryRightMarginButton onClick={tagStore.onManageModalOpen}>
        Управление
      </StyledPrimaryRightMarginButton>
    </StyledInputConteiner>
  );
});

const IllustrationInput: React.FC<{
  taskStore: TaskStore;
  startValue?: { author?: string };
}> = observer(({ taskStore, startValue }) => {
  return (
    <>
      <StyledInputConteiner>
        <StyledLabel>Фон</StyledLabel>
        <StyledFileInput
          key={"taskIllustration" + new Date().getMilliseconds()}
          type="file"
          onChange={taskStore.addImageFromFile}
        ></StyledFileInput>
        {taskStore.taskIllustration && (
          <StyledPrimaryRightMarginButton onClick={taskStore.deleteImage}>
            Убрать фон
          </StyledPrimaryRightMarginButton>
        )}
      </StyledInputConteiner>
      {taskStore.taskIllustration && (
        <StyledInputConteiner>
          <StyledLabel>Автор фона</StyledLabel>
          <StyledInputWithBorderWithGrow
            key={"taskIllustrationAuthor" + new Date().getMilliseconds()}
            type="string"
            onChange={taskStore.onChangeIllustrationAuthor}
            defaultValue={startValue?.author}
          ></StyledInputWithBorderWithGrow>
        </StyledInputConteiner>
      )}
    </>
  );
});

export const TaskCreateWindow: React.FC<{}> = () => {
  // Set initial state
  let loaderData: any = useLoaderData();
  let task: TaskShowApi = new StartTaskData();
  if (loaderData.taskData !== null && loaderData.taskData !== undefined) {
    task = new TaskShowDataFromResponse(loaderData.taskData);
  }
  let taskTypes: Type[] = loaderData.allTaskTypes;
  let taskStore = new TaskStore(task);
  taskStore.taskTypeId = taskTypes[0].taskTypeId;

  return (
    <div className="create-window">
      <SaveWindowTitle store={taskStore} />
      <StyledInputConteiner>
        <StyledLabel>Публичное название</StyledLabel>
        <StyledInputWithBorderWithGrow
          key={"taskPublicName" + new Date().getMilliseconds()}
          type="text"
          onChange={taskStore.onChangePublicName}
          defaultValue={task.taskPublicName}
          required
        ></StyledInputWithBorderWithGrow>
      </StyledInputConteiner>
      <StyledInputConteiner>
        <StyledLabel>Приватное название</StyledLabel>
        <StyledInputWithBorderWithGrow
          key={"taskPrivateName" + new Date().getMilliseconds()}
          type="text"
          onChange={taskStore.onChangePrivateName}
          defaultValue={task.taskPrivateName}
          required
        ></StyledInputWithBorderWithGrow>
      </StyledInputConteiner>
      <StyledInputConteiner>
        <StyledLabel>Тип</StyledLabel>
        <StyledSelect
          key={"taskType" + new Date().getMilliseconds()}
          onChange={taskStore.onChangeType}
          defaultValue={task.taskTypeId}
          required
        >
          {taskTypes.map((type: Type) => {
            return (
              <option value={type.taskTypeId}>
                {
                  dictionary.ru.translation[
                    type.typeName as keyof typeof dictionary.ru.translation
                  ]
                }
              </option>
            );
          })}
        </StyledSelect>
      </StyledInputConteiner>
      <StyledInputConteiner>
        <StyledLabel>Статус</StyledLabel>
        <StyledSelect
          key={"taskStatus" + new Date().getMilliseconds()}
          onChange={taskStore.onChangeStatus}
          defaultValue={task.taskStatusId}
          required
        >
          <option key="0" value={0}>
            В разработке
          </option>
          <option key="1" value={1}>
            Демо
          </option>
          <option key="2" value={2}>
            В модуле
          </option>
          <option key="3" value={3}>
            В резерве
          </option>
        </StyledSelect>
      </StyledInputConteiner>
      <StyledInputConteiner>
        <StyledLabel>Содержимое</StyledLabel>
        <StyledTextArea
          key={"taskContent" + new Date().getMilliseconds()}
          onChange={taskStore.onChangeContent}
          rows={15}
          defaultValue={task.taskContent}
          required
        ></StyledTextArea>
      </StyledInputConteiner>
      <TagsPicker
        tagStore={TagStoreObj}
        taskStore={taskStore}
        startValue={task.taskTagsIds}
      />
      <StyledInputConteiner>
        <StyledLabel>Стоимость (в котобонах)</StyledLabel>
        <StyledInputWithBorderWithGrow
          key={"taskCost" + new Date().getMilliseconds()}
          type="number"
          min={0}
          onChange={taskStore.onChangeCost}
          defaultValue={task.taskCost}
        ></StyledInputWithBorderWithGrow>
      </StyledInputConteiner>
      <StyledInputConteiner>
        <StyledLabel>Вид проверки</StyledLabel>
        <StyledSelect
          key={"taskTypeOfCheck" + new Date().getMilliseconds()}
          onChange={taskStore.onChangeTypeOfCheck}
          defaultValue={task.taskTypeOfCheck}
          required
        >
          <option key="0" value={0}>
            Ручная проверка
          </option>
          <option key="1" value={1}>
            Автоматическая проверка
          </option>
        </StyledSelect>
      </StyledInputConteiner>
      <IllustrationInput
        taskStore={taskStore}
        startValue={{ author: task.taskIllustration?.author }}
      ></IllustrationInput>
      <TaskImage taskStore={taskStore}></TaskImage>

      <SaveWindowButton store={taskStore} />
    </div>
  );
};
