// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  background-color: white;
  margin: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.title {
  font-family: "Amatic SC";
  font-size: 56pt;
  text-align: center;
}

.subtitle {
  font-family: "Amatic SC", cursive;
  font-size: 28pt;
  text-align: center;
}

.auth--options {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Content.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;EACZ,4EAA4E;AAC9E;;AAEA;EACE,wBAAwB;EACxB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,iCAAiC;EACjC,eAAe;EACf,kBAAkB;AACpB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;EACrB","sourcesContent":[".content {\n  background-color: white;\n  margin: auto;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n}\n\n.title {\n  font-family: \"Amatic SC\";\n  font-size: 56pt;\n  text-align: center;\n}\n\n.subtitle {\n  font-family: \"Amatic SC\", cursive;\n  font-size: 28pt;\n  text-align: center;\n}\n\n.auth--options {\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
