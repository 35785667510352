// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter {
  margin: 0px;
  flex: 0 0 250px;
}

.filter h1, .filter-checkbox-div {
  margin: 20px;
}

.filter-checkbox-div .filter-head {
  margin: 10px 0px;
  font-weight: bold;
}

.filter-checkbox-div .filter-item {
  padding: 5px;
}

.filter-checkbox-div .filter-item input {
  margin: 0px 5px;
}
`, "",{"version":3,"sources":["webpack://./src/ProfileWindow/AdminWindows/TaskListWindow/TaskListComponents/TaskListFilter.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".filter {\n  margin: 0px;\n  flex: 0 0 250px;\n}\n\n.filter h1, .filter-checkbox-div {\n  margin: 20px;\n}\n\n.filter-checkbox-div .filter-head {\n  margin: 10px 0px;\n  font-weight: bold;\n}\n\n.filter-checkbox-div .filter-item {\n  padding: 5px;\n}\n\n.filter-checkbox-div .filter-item input {\n  margin: 0px 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
