export enum TaskStatusEnum {
  InDevelopment = 0,
  Demo,
  InModule,
  Reserved,
}

export const allTaskStatuses: TaskStatusEnum[] = [
  TaskStatusEnum.InDevelopment,
  TaskStatusEnum.Demo,
  TaskStatusEnum.InModule,
  TaskStatusEnum.Reserved,
];

export const allTaskStatusesWithText: Map<TaskStatusEnum, string> = new Map([
  [TaskStatusEnum.InDevelopment, "В разработке"],
  [TaskStatusEnum.Demo, "Демо"],
  [TaskStatusEnum.InModule, "В модуле"],
  [TaskStatusEnum.Reserved, "В резерве"],
]);
