import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

export const ProfileNavLink: React.FC<{ to: string; children: ReactNode }> = ({
  to,
  children,
}) => {
  return (
    <NavLink
      to={to}
      className={({ isActive, isPending }) =>
        isPending ? "pending" : isActive ? "active" : ""
      }
    >
      {children}
    </NavLink>
  );
};
