// import Fuse from "fuse.js";
import { computed, makeAutoObservable } from "mobx";

const fuseOptions = {
  isCaseSensitive: false,
  shouldSort: false,
  keys: ["text"],
};

export type dataFilterType = {
  value: string;
  text: string;
};

export class FilterStore {
  public searchTextPattern: string = "";
  public data: dataFilterType[];
  // public fuse: Fuse<dataFilterType>;

  constructor(data: dataFilterType[]) {
    makeAutoObservable(this);

    this.data = data;
   //  this.fuse = new Fuse(data, fuseOptions);
  }

  public onChangeSearchTextPattern = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    this.searchTextPattern = event.currentTarget.value;
  };

  @computed get searchedDataFilter(): dataFilterType[] {
   // if (this.searchTextPattern !== "")
   //   return this.fuse.search(this.searchTextPattern).map(({ item }) => item);
   // else 
    return this.data;
  }
}
