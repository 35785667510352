import {
  IObservableArray,
  computed,
  makeAutoObservable,
  observable,
} from "mobx";
import { TaskPropApi, TaskResponseApi } from "../DTO/ServerResponseType/Task";
import TaskSender from "../SendersСlientRequests/TaskSender";
// import Fuse from "fuse.js";
import { LittleTaskApi } from "../ProfileWindow/AdminWindows/TaskListWindow/DTO/LittleTaskAPI";
import { TaskSortMap } from "../utils/sort/taskListSort/options";
import { SortOptionsForTaskList } from "../Enums/TaskStoreOptions";

export interface TasksStoreApi {
  onDeleteModalClose: () => void;
  setTasks: (tasks: TaskResponseApi[]) => void;
  deleteTask(taskId: string): void;

  deleteTaskData: LittleTaskApi;
  showDeleteModal: boolean;
}

// const fuseOptions = {
//   isCaseSensitive: false,
//   shouldSort: false,
//   keys: ["taskPublicName", "taskPrivateName"],
// };

export enum TaskListViews {
  cards,
  table,
}

class TaskStore implements TasksStoreApi {
  public tasks: IObservableArray<TaskResponseApi> = observable.array([]);
  public showDeleteModal: boolean = false;
  public deleteTaskData!: LittleTaskApi;
  public searchTaskNamePattern: string = "";
  // public fuse: Fuse<TaskResponseApi> = new Fuse(this.tasks, fuseOptions);
  public taskListView: TaskListViews = TaskListViews.cards;
  public chosenTaskStatuseIds: number[] = [];
  public chosenTaskTypesIds: string[] = [];
  public chosenTaskTagsIds: string[] = [];
  public chosenSortedOption: SortOptionsForTaskList =
    SortOptionsForTaskList.modificationTimeDesc;

  constructor() {
    makeAutoObservable(this);
  }

  public onDeleteModalOpen = (taskData: LittleTaskApi) => {
    this.showDeleteModal = true;
    this.deleteTaskData = taskData;
  };

  public onDeleteModalClose = () => {
    this.showDeleteModal = false;
  };

  public setTasks = (tasks: TaskResponseApi[]) => {
    this.tasks.replace(tasks);
  //  this.fuse.setCollection(tasks);
  };

  public deleteTask = (taskId: string) => {
    TaskSender.sendToDeleteTask(taskId)
      .then(() => {
        this.tasks.replace(
          this.tasks.filter((a: TaskPropApi) => a.taskId !== taskId)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  public onChangeTaskView = () => {
    this.taskListView =
      this.taskListView === TaskListViews.cards
        ? TaskListViews.table
        : TaskListViews.cards;
  };

  @computed get taskViewButtonText(): string {
    let taskViewButtonText = "";

    if (this.taskListView === TaskListViews.table)
      taskViewButtonText = "Карточки";

    if (this.taskListView === TaskListViews.cards)
      taskViewButtonText = "Таблица";

    return taskViewButtonText;
  }
  public onChangeSearchTaskNamePattern = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    this.searchTaskNamePattern = event.currentTarget.value;
  };

  private onChangeFilter<T>(
    currentTargetChecked: boolean,
    currentTargetValue: T,
    changedArray: T[]
  ) {
    if (currentTargetChecked) changedArray.push(currentTargetValue);
    else changedArray = changedArray.filter((el) => el != currentTargetValue);
  }

  public onChangeStatusFilter = (event: React.FormEvent<HTMLInputElement>) => {
    this.onChangeFilter(
      event.currentTarget.checked,
      parseInt(event.currentTarget.value),
      this.chosenTaskStatuseIds
    );
  };

  public onChangeTypeFilter = (event: React.FormEvent<HTMLInputElement>) => {
    this.onChangeFilter(
      event.currentTarget.checked,
      event.currentTarget.value,
      this.chosenTaskTypesIds
    );
  };

  public onChangeTagFilter = (event: React.FormEvent<HTMLInputElement>) => {
    this.onChangeFilter(
      event.currentTarget.checked,
      event.currentTarget.value,
      this.chosenTaskTagsIds
    );
  };

  public onChangeSortedOptions = (
    event: React.FormEvent<HTMLSelectElement>
  ) => {
    this.chosenSortedOption = parseInt(
      event.currentTarget.options[event.currentTarget.selectedIndex].value
    );
  };

  @computed get searchedAndFilteredAndSortedTasks(): TaskResponseApi[] {
    let searchedAndFilteredAndSortedTasks: TaskResponseApi[] = [];

    // search
//    if (this.searchTaskNamePattern !== "") {
 //     let fuseResult = this.fuse.search(this.searchTaskNamePattern);
 //     searchedAndFilteredAndSortedTasks = fuseResult.map(({ item }) => item);
  //  } else 
    searchedAndFilteredAndSortedTasks = this.tasks.toJSON();

    // filter
    if (this.chosenTaskStatuseIds.length !== 0)
      searchedAndFilteredAndSortedTasks =
        searchedAndFilteredAndSortedTasks.filter((task: TaskResponseApi) =>
          this.chosenTaskStatuseIds.includes(task.taskStatusId)
        );

    if (this.chosenTaskTypesIds.length !== 0)
      searchedAndFilteredAndSortedTasks =
        searchedAndFilteredAndSortedTasks.filter((task: TaskResponseApi) =>
          this.chosenTaskTypesIds.includes(task.taskTypeId)
        );

    if (this.chosenTaskTagsIds.length !== 0)
      searchedAndFilteredAndSortedTasks =
        searchedAndFilteredAndSortedTasks.filter((task: TaskResponseApi) => {
          let result = true;
          this.chosenTaskTagsIds.map((taskTagId) => {
            result =
              result &&
              task.taskTags.map(({ tag }) => tag.tagId).includes(taskTagId);
          });
          return result;
        });

    // sort
    let sortOptions = TaskSortMap.get(this.chosenSortedOption);
    if (sortOptions !== undefined)
      searchedAndFilteredAndSortedTasks =
        searchedAndFilteredAndSortedTasks.sort(sortOptions.func);

    return searchedAndFilteredAndSortedTasks;
  }
}

export default new TaskStore();
