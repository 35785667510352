import { NewsItemResponse } from "../DTO/ServerResponseType/NewsItem";
import { NewsItemStore } from "../Stores/NewsItemStore";
import { Sender, serverOrigin } from "./Configs";

class NewsSender {
  private path = "/news";

  sendToUpdateNews = async (newsData: NewsItemStore) => {
    if (newsData.isAddPreviewTextToHtml === true) {
      newsData.addPreviewTextToHtml();
    }

    await this.sendUpdatedNews(newsData)
      .then(() => {
        alert("Новость успешно изменена.");
      })
      .catch((error) => {
        console.error("Ошибка:", error);
      });

    return null;
  };

  sendUpdatedNews = async (newsData: NewsItemStore) => {
    let url: string = serverOrigin + this.path + "/update/all";

    let response = await Sender.sendRequest({
      url: url,
      method: "POST",
      body: JSON.stringify({ newsId: newsData.newsId, newsNewData: newsData }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response;
  };

  sendToCreateNews = async (newsData: NewsItemStore): Promise<string> => {
    if (newsData.isAddPreviewTextToHtml === true) {
      newsData.addPreviewTextToHtml();
    }

    let newsId = await this.sendNewNews(newsData)
      .then((response) => {
        alert("Новость успешно создана.");
        let newsId = response.body.newsId;
        return newsId;
      })
      .catch((error) => {
        console.error("Ошибка:", error);
      });

    return newsId;
  };

  sendNewNews = async (newsData: NewsItemStore) => {
    let url: string = serverOrigin + this.path + "/create";

    let response = await Sender.sendRequest({
      url: url,
      method: "POST",
      body: JSON.stringify(newsData),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response;
  };

  sendNewsImage = async (newsId: string, imageData: File) => {
    let url: string = serverOrigin + this.path + "/image/create";

    try {
      const formData = new FormData();
      formData.append("newsId", newsId);
      formData.append("imageData", imageData);

      let response = await Sender.sendRequest({
        url: url,
        method: "POST",
        body: formData,
      });

      return response;
    } catch (error) {
      console.error("Ошибка:", error);
    }
  };

  async sendToDeleteNewsImage(newsImageId: string) {
    let url: string = serverOrigin + this.path + "/image/delete";
    console.log(JSON.stringify({ newsImageId: newsImageId }));
    try {
      let response = await Sender.sendRequest({
        url: url,
        method: "POST",
        body: JSON.stringify({ newsImageId: newsImageId }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      return response;
    } catch (error) {
      console.error("Ошибка:", error);
    }
  }

  sendToGetAllNews = async () => {
    let url: string = serverOrigin + this.path + "/show/all";
    try {
      let response = await Sender.sendRequest({
        url: url,
        method: "GET",
      });

      return response;
    } catch (error) {
      console.error("Ошибка:", error);
    }
  };

  sendToGetAnyNews = async (
    countNews: number
  ): Promise<{ body: NewsItemResponse[] } | undefined> => {
    let url: string =
      serverOrigin + this.path + "/show/all" + "?countNews=" + countNews;
    try {
      let response = await Sender.sendRequest({
        url: url,
        method: "GET",
      });

      return response;
    } catch (error) {
      console.error("Ошибка:", error);
    }
  };

  sendToGetNewsItemByNewsItemId = async (newsId: string) => {
    let url: string =
      serverOrigin + this.path + "/show/one" + "?newsId=" + newsId;
    try {
      let response = await Sender.sendRequest({
        url: url,
        method: "GET",
      });

      return response;
    } catch (error) {
      console.error("Ошибка:", error);
    }
  };

  sendToDeleteNews = async (newsId: string) => {
    let url: string = serverOrigin + this.path + "/delete";

    try {
      let response = await Sender.sendRequest({
        url: url,
        method: "POST",
        body: JSON.stringify({ newsId: newsId }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      return response;
    } catch (error) {
      console.error("Ошибка:", error);
    }
  };
}

export default new NewsSender();
