import {
  TaskData,
  TaskDataToCreate,
  TaskResponseData,
} from "../DTO/ServerResponseType/Task";
import { TaskStore } from "../Stores/TaskStore";
import { Sender, serverOrigin } from "./Configs";

class TaskSender {
  async sendToGetAllTasks() {
    let url: string = serverOrigin + this.path + "/show/all";
    try {
      let response = await Sender.sendRequest({
        url: url,
        method: "GET",
      });

      return response;
    } catch (error) {
      console.error("Ошибка:", error);
    }
  }
  async sendToDeleteTask(taskId: string) {
    let url: string = serverOrigin + this.path + "/delete";

    try {
      let response = await Sender.sendRequest({
        url: url,
        method: "POST",
        body: JSON.stringify({ taskId: taskId }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      return response;
    } catch (error) {
      console.error("Ошибка:", error);
    }
  }
  private path = "/task";

  sendToUpdateTask = async (taskData: TaskStore) => {
    await this.sendUpdatedTask(taskData)
      .then(() => {
        alert("Задача успешно изменена.");
      })
      .catch((error) => {
        console.error("Ошибка:", error);
      });

    return null;
  };

  sendUpdatedTask = async (taskStore: TaskStore) => {
    let url: string = serverOrigin + this.path + "/update/all";

    const formData = new FormData();
    let taskNewData = new TaskData(taskStore);
    formData.append(
      "taskData",
      JSON.stringify({ taskId: taskStore.taskId, taskNewData: taskNewData })
    );

    if (taskStore.taskIllustration !== undefined) {
      formData.append(
        "illustrationNewData",
        JSON.stringify({
          illustrationAuthor: taskStore.taskIllustration.author,
        })
      );
      formData.append("illustration", taskStore.taskIllustration.data);
    }

    let response = await Sender.sendRequest({
      url: url,
      method: "POST",
      body: formData,
    });

    return response;
  };

  sendToUpdateTaskStatus = async (taskStore: TaskStore) => {
    await this.sendUpdatedTaskStatus(taskStore)
      .then(() => {
        alert("У задачи успешно изменен статус");
      })
      .catch((error) => {
        console.error("Ошибка:", error);
      });

    return null;
  };

  sendUpdatedTaskStatus = async (taskStore: TaskStore) => {
    let url: string = serverOrigin + this.path + "/update/status";
    let response = await Sender.sendRequest({
      url: url,
      method: "POST",
      body: JSON.stringify({
        taskId: taskStore.taskId,
        taskStatusId: taskStore.taskStatusId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response;
  };

  sendToGetTaskByTaskIdAsync = async (
    taskId: string
  ): Promise<{ body: TaskResponseData } | undefined> => {
    let url: string =
      serverOrigin + this.path + "/show/one" + "?taskId=" + taskId;
    try {
      let response = await Sender.sendRequest({
        url: url,
        method: "GET",
      });

      return response;
    } catch (error) {
      console.error("Ошибка:", error);
    }
  };

  sendToCreateTask = async (taskData: TaskStore): Promise<string> => {
    let taskId = await this.sendNewTask(taskData)
      .then((response) => {
        alert("Задача успешно создана.");
        let taskId = response.body.taskId;
        return taskId;
      })
      .catch((error) => {
        console.error("Ошибка:", error);
      });

    return taskId;
  };

  sendNewTask = async (taskStore: TaskStore) => {
    let url: string = serverOrigin + this.path + "/create";

    const formData = new FormData();
    let taskData = new TaskData(taskStore);
    formData.append("taskData", JSON.stringify(taskData));

    if (taskStore.taskIllustration !== undefined) {
      formData.append(
        "illustrationData",
        JSON.stringify({
          illustrationAuthor: taskStore.taskIllustration.author,
        })
      );
      formData.append("illustration", taskStore.taskIllustration.data);
    }

    let response = await Sender.sendRequest({
      url: url,
      method: "POST",
      body: formData,
    });

    return response;
  };

  sendToGetTaskTypesAndTaskTags = async (): Promise<
    { body: TaskDataToCreate } & { status: number; message: string }
  > => {
    let url: string = serverOrigin + this.path + "/data/toCreate";

    let taskDataToCreate = await Sender.sendRequest({
      url: url,
      method: "GET",
    }).catch((error) => {
      console.error("Ошибка:", error);
    });

    return taskDataToCreate;
  };
}

export default new TaskSender();
