import { Content } from "../Components/Content";
import { Link, NavLink, Outlet } from "react-router-dom";
import "./Profile.css";
import NewsStore from "../Stores/NewsStore";
import { DeleteNewsModal } from "../NewsWindows/NewsListWindow/DeleteNewsModal";
import { observer } from "mobx-react";
import { StyledTitleH1 } from "../Components/StyledComponents/Text";
import { ManageTagsModal } from "./AdminWindows/TaskEditWindow/ManageTagsModal";
import TagStore from "../Stores/TagStore";
import { ProfileNavLink } from "./ProfileComponents/ProfileNavLink";

export const MyProfile2 = observer(() => {
  console.log("render profile");
  return (
    <Content isBlack={true} width="100%">
      <StyledTitleH1>Кабинет администратора</StyledTitleH1>
      <div className="profile tutor-profile">
        <div className="navPanel">
          <ProfileNavLink to="./news/list">
            <div>Новости</div>
          </ProfileNavLink>
          <ProfileNavLink to="./news/create">
            <div>Создать новость</div>
          </ProfileNavLink>
          <ProfileNavLink to="./task/list">
            <div>Банк задач</div>
          </ProfileNavLink>
          <ProfileNavLink to="./task/create">
            <div>Создать задачу</div>
          </ProfileNavLink>
        </div>
        <div className="contentWindow">
          <Outlet />
        </div>
      </div>
      {NewsStore.showDeleteModal && <DeleteNewsModal newsStore={NewsStore} />}
      {TagStore.showManageTags && <ManageTagsModal tagsStore={TagStore} />}
    </Content>
  );
});
