import {
  IObservableArray,
  makeAutoObservable,
  observable,
  computed,
} from "mobx";
import { TaskShowApi } from "../DTO/ServerResponseType/Task";
import { SaveStoreApi } from "./API/SaveStore";
import TaskSender from "../SendersСlientRequests/TaskSender";
export type TaskIllustration = {
  // The file type is used for uploading data by the user
  // The string - from the server
  data: File | string;
  src: string;
  author?: string;
};

export class TaskStore implements SaveStoreApi {
  public taskId: string = "";
  public taskPrivateName: string = "";
  public taskPublicName: string = "";
  public taskTypeId: string = "";
  public taskStatusId: number = 0;
  public taskContent: string = "";
  public taskCost: number = 0;
  public taskTypeOfCheck: number = 0;
  public taskIllustration?: TaskIllustration;
  public taskIllustrationId?: string;
  public taskCreationTime!: Date;
  public taskModificationTime!: Date;
  public taskTagsIds: IObservableArray<string> = observable.array([]);

  constructor(task: TaskShowApi) {
    makeAutoObservable(this);
    this.setTask(task);
  }

  public addImageFromFile = (event: React.FormEvent<HTMLInputElement>) => {
    // File was not selected
    if (event.currentTarget.files === null) return;

    let file = event.currentTarget.files[0];
    this.taskIllustration = {
      src: URL.createObjectURL(file),
      data: file,
    };
  };

  public deleteImage = () => {
    this.taskIllustration = undefined;
  };

  onChangePublicName = (event: React.FormEvent<HTMLInputElement>) => {
    this.taskPublicName = event.currentTarget.value;
  };

  onChangePrivateName = (event: React.FormEvent<HTMLInputElement>) => {
    this.taskPrivateName = event.currentTarget.value;
  };

  onChangeCost = (event: React.FormEvent<HTMLInputElement>) => {
    this.taskCost = parseInt(event.currentTarget.value);
  };

  onChangeType = (event: React.FormEvent<HTMLSelectElement>) => {
    this.taskTypeId =
      event.currentTarget.options[event.currentTarget.selectedIndex].value;
  };

  onChangeStatus = (event: React.FormEvent<HTMLSelectElement>) => {
    this.taskStatusId = parseInt(event.currentTarget.value);
  };

  onChangeTypeOfCheck = (event: React.FormEvent<HTMLSelectElement>) => {
    this.taskTypeOfCheck = parseInt(event.currentTarget.value);
  };

  onChangeContent = (event: React.FormEvent<HTMLTextAreaElement>) => {
    this.taskContent = event.currentTarget.value;
  };

  onChangeTags = (tagsIds: string[]) => {
    this.taskTagsIds.replace(tagsIds);
  };

  onChangeIllustrationAuthor = (event: React.FormEvent<HTMLInputElement>) => {
    this.taskIllustration!.author = event.currentTarget.value;
  };

  @computed get saveWindowTitle(): string {
    return this.taskId === "" ? "Создание задачи" : "Обновление задачи";
  }

  @computed get saveButtonText(): string {
    return this.taskId === "" ? "Создать задачу" : "Обновить задачу";
  }

  @computed get saveClick() {
    if (this.taskId === "") this.taskCreationTime = new Date();
    this.taskModificationTime = new Date();

    return (
      this.taskId === ""
        ? TaskSender.sendToCreateTask(this)
        : TaskSender.sendToUpdateTask(this)
    ).then((taskId) => {
      if (taskId !== null && taskId !== undefined) {
        this.taskId = taskId;
      }
      TaskSender.sendToUpdateTaskStatus(this);
      return taskId;
    });
  }

  public setTask = (task: TaskShowApi) => {
    this.taskId = task.taskId ? task.taskId : "";
    this.taskPrivateName = task.taskPrivateName;
    this.taskPublicName = task.taskPublicName;
    this.taskTypeId = task.taskTypeId;
    this.taskStatusId = task.taskStatusId;
    this.taskContent = task.taskContent;
    this.taskCost = task.taskCost;
    this.taskTypeOfCheck = task.taskTypeOfCheck;
    this.taskTagsIds.replace(task.taskTagsIds);
    this.taskIllustration = task.taskIllustration;
    this.taskIllustrationId = task.taskIllustrationId;
    this.taskCreationTime = task.taskCreationTime;
    this.taskModificationTime = task.taskModificationTime;
  };

  setId = (taskId: string) => {
    this.taskId = taskId;
  };
}
