// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news--html {
  font-family: "Manrope";
  text-align: justify;
  font-size: 18px;
}

.news--html table {
  width: 100%;
  font-size: 0.857em;
  text-align: start;
  border: 2px solid;
  border-radius: 10px;
  overflow: hidden;
  border-spacing: 0px;
}

.news--html thead {
  background-color: #67ab9f;
  color: white;
  text-align: center;
}
.news--html td {
  padding: 10px;
  border: 0px;
}

.news--html td:last-child {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/NewsWindows/NewsItemWindow/NewsHtml.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":[".news--html {\n  font-family: \"Manrope\";\n  text-align: justify;\n  font-size: 18px;\n}\n\n.news--html table {\n  width: 100%;\n  font-size: 0.857em;\n  text-align: start;\n  border: 2px solid;\n  border-radius: 10px;\n  overflow: hidden;\n  border-spacing: 0px;\n}\n\n.news--html thead {\n  background-color: #67ab9f;\n  color: white;\n  text-align: center;\n}\n.news--html td {\n  padding: 10px;\n  border: 0px;\n}\n\n.news--html td:last-child {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
