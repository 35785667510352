import { TaskPropApi } from "../../../DTO/ServerResponseType/Task";

export function sortTaskListByPublicNameAsc(
  a: TaskPropApi,
  b: TaskPropApi
): number {
  return new Intl.Collator().compare(a.taskPublicName, b.taskPublicName);
}

export function sortTaskListByPublicNameDesc(
  a: TaskPropApi,
  b: TaskPropApi
): number {
  return -sortTaskListByPublicNameAsc(a, b);
}

export function sortTaskListByCreationTimeAsc(
  a: TaskPropApi,
  b: TaskPropApi
): number {
  let aCT = new Date(a.taskCreationTime).getTime();
  let bCT = new Date(b.taskCreationTime).getTime();

  return aCT - bCT;
}

export function sortTaskListByCreationTimeDesc(
  a: TaskPropApi,
  b: TaskPropApi
): number {
  return -sortTaskListByCreationTimeAsc(a, b);
}

export function sortTaskListByModificationTimeAsc(
  a: TaskPropApi,
  b: TaskPropApi
): number {
  let aMT = new Date(a.taskModificationTime).getTime();
  let bMT = new Date(b.taskModificationTime).getTime();

  return aMT - bMT;
}

export function sortTaskListByModificationTimeDesc(
  a: TaskPropApi,
  b: TaskPropApi
): number {
  return -sortTaskListByModificationTimeAsc(a, b);
}
