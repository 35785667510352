import {
  IObservableArray,
  computed,
  makeAutoObservable,
  observable,
} from "mobx";
import NewsSender from "../SendersСlientRequests/NewsSender";
import { NewsItemResponse } from "../DTO/ServerResponseType/NewsItem";
import { SaveStoreApi } from "./API/SaveStore";

export type NewsImage = {
  id: string;
  // The file type is used for uploading data by the user
  // The string - from the server
  data: File | string;
  src: string;
};

export class NewsItemStore implements SaveStoreApi {
  public newsId: string = "";
  public newsTitle: string = "";
  public newsPreviewText: string = "";
  public newsAuthor: string = "";
  public newsHtml: string = "";
  public newsDate: string = "";
  public newsImages: IObservableArray<NewsImage> = observable.array([]);
  public isAddPreviewTextToHtml: boolean = false;

  constructor(newsItem: NewsItemResponse) {
    makeAutoObservable(this);
    this.setNewsItem(newsItem);
  }
  public onChangeId = (newsId: string) => {
    this.newsId = newsId;
  };
  public onChangeTitle = (event: React.FormEvent<HTMLInputElement>) => {
    this.newsTitle = event.currentTarget.value;
  };
  public onChangePreviewText = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    this.newsPreviewText = event.currentTarget.value;
  };

  public setIsAddPreviewTextToHtml = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    this.isAddPreviewTextToHtml = event.currentTarget.checked;
  };

  public setId = (newsId: string) => {
    this.newsId = newsId;
  };

  public addPreviewTextToHtml = () => {
    this.newsHtml =
      "<div><p>" + this.newsPreviewText + "</p>" + this.newsHtml + "</div>";
  };

  public onChangeAuthor = (event: React.FormEvent<HTMLInputElement>) => {
    this.newsAuthor = event.currentTarget.value;
  };
  public onChangeHtml = (event: React.FormEvent<HTMLTextAreaElement>) => {
    this.newsHtml = event.currentTarget.value;
  };
  public onChangeDate = (event: React.FormEvent<HTMLInputElement>) => {
    this.newsDate = event.currentTarget.value;
  };

  /**
   * Add image to store and to database on the server
   * @param event
   * @returns
   */
  public addImageFromFile = (event: React.FormEvent<HTMLInputElement>) => {
    // File was not selected
    if (event.currentTarget.files === null) return;

    // News item was not created
    if (this.newsId === "" || this.newsId === undefined || this.newsId === null)
      return;

    let file = event.currentTarget.files[0];
    NewsSender.sendNewsImage(this.newsId, file).then((response) => {
      let newsImageId: string = response.body.newsImageId;
      this.newsImages.push({
        id: newsImageId,
        src: URL.createObjectURL(file),
        data: file,
      });
    });
  };

  public deleteImage = (imageId: string) => {
    NewsSender.sendToDeleteNewsImage(imageId).then(() => {
      this.newsImages.replace(
        this.newsImages.filter((image: NewsImage) => image.id !== imageId)
      );
    });
  };

  public setNewsItem = (newsItem: NewsItemResponse) => {
    this.newsId = newsItem.newsId;
    this.newsTitle = newsItem.newsTitle;
    this.newsPreviewText = newsItem.newsPreviewText;
    this.newsAuthor = newsItem.newsAuthor;
    this.newsHtml = newsItem.newsHtml;
    this.newsDate = newsItem.newsDate;

    this.newsImages.replace(
      Array.from(newsItem.newsImages, (image) => {
        return {
          id: image.newsImageId,
          data: image.image,
          src: "data:image/png;base64," + image.image,
        };
      })
    );
  };

  @computed get saveWindowTitle(): string {
    return this.newsId === "" ? "Создание новости" : "Обновление новости";
  }

  @computed get saveButtonText(): string {
    return this.newsId === "" ? "Создать новость" : "Обновить новость";
  }

  @computed get newsAllowImages(): boolean {
    return this.newsId === "" ? false : true;
  }

  @computed get saveClick() {
    return this.newsId === ""
      ? NewsSender.sendToCreateNews(this)
      : NewsSender.sendToUpdateNews(this);
  }
}
