import { IObservableArray, makeAutoObservable, observable } from "mobx";
import NewsSender from "../SendersСlientRequests/NewsSender";
import { NewsPropImpl } from "../NewsWindows/NewsListWindow/NewsList";
import { LittleNewsApi } from "../NewsWindows/NewsListWindow/LittleNews";

export interface NewsStoreApi {
  onDeleteModalClose: () => void;
  setNews: (news: NewsPropImpl[]) => void;
  deleteNewsItem(newsItemId: string): void;

  deleteNewsData: LittleNewsApi;
  showDeleteModal: boolean;
}

class NewsStore implements NewsStoreApi {
  public news: IObservableArray<NewsPropImpl> = observable.array([]);
  public showDeleteModal: boolean = false;
  public deleteNewsData!: LittleNewsApi;

  constructor() {
    makeAutoObservable(this);
  }

  public onDeleteModalOpen = (newsData: LittleNewsApi) => {
    this.showDeleteModal = true;
    this.deleteNewsData = newsData;
  };

  public onDeleteModalClose = () => {
    this.showDeleteModal = false;
  };

  public setNews = (news: any) => {
    this.news.replace(news);
  };

  public deleteNewsItem = (newsItemId: string) => {
    NewsSender.sendToDeleteNews(newsItemId)
      .then(() => {
        this.news.replace(
          this.news.filter((a: any) => a.newsId !== newsItemId)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export default new NewsStore();
