import { TaskPropApi } from "../../../DTO/ServerResponseType/Task";
import { SortOptionsForTaskList } from "../../../Enums/TaskStoreOptions";
import {
  sortTaskListByCreationTimeAsc,
  sortTaskListByCreationTimeDesc,
  sortTaskListByModificationTimeAsc,
  sortTaskListByModificationTimeDesc,
  sortTaskListByPublicNameAsc,
  sortTaskListByPublicNameDesc,
} from "./functions";

export const TaskSortMap: Map<
  SortOptionsForTaskList,
  { func: (a: TaskPropApi, b: TaskPropApi) => number; text: string }
> = new Map([
  [
    SortOptionsForTaskList.publicNameAsc,
    { func: sortTaskListByPublicNameAsc, text: "по названию (возрастание)" },
  ],
  [
    SortOptionsForTaskList.publicNameDesc,
    { func: sortTaskListByPublicNameDesc, text: "по названию (убывание)" },
  ],
  [
    SortOptionsForTaskList.creationTimeAsc,
    {
      func: sortTaskListByCreationTimeAsc,
      text: "по дате создания (сначала старые)",
    },
  ],
  [
    SortOptionsForTaskList.creationTimeDesc,
    {
      func: sortTaskListByCreationTimeDesc,
      text: "по дате создания (сначала новые)",
    },
  ],
  [
    SortOptionsForTaskList.modificationTimeAsc,
    {
      func: sortTaskListByModificationTimeAsc,
      text: "по дате изменения (сначала старые)",
    },
  ],
  [
    SortOptionsForTaskList.modificationTimeDesc,
    {
      func: sortTaskListByModificationTimeDesc,
      text: "по дате изменения (сначала новые)",
    },
  ],
]);
