import { NavLink } from "react-router-dom";
import { LittleTaskApi } from "../../DTO/LittleTaskAPI";

export const LittleTaskModules: React.FC<{ taskData: LittleTaskApi }> = ({
  taskData,
}) => {
  return (
    <>
      {taskData.taskModules.map((taskModule) => (
        <NavLink
          className="underline"
          to={`/module/one/${taskModule.moduleId}`}
        >
          {taskModule.moduleName}
        </NavLink>
      ))}
    </>
  );
};
