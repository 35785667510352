import { styled } from "styled-components";
import { ConteinerBg } from "./Containers";

export const Span = styled.span`
  font-family: "Manrope";
  font-size: 18px;
  padding: 3px;
`;

export const StyledDateSpan = styled(Span)`
  color: ${(ConteinerBg.white)};
  background-color: ${(ConteinerBg.green)};
`;

export const StyledTextSpan = styled(Span)`
  color: ${(ConteinerBg.green)};
`;
