import { observer } from "mobx-react";
import TasksStore, { TaskListViews } from "../../../Stores/TasksStore";
import { TaskResponseApi } from "../../../DTO/ServerResponseType/Task";
import {
  LittleTaskCard,
  LittleTaskTableRow,
} from "./TaskListComponents/LittleTaskCard";
import { StyledTitleH2 } from "../../../Components/StyledComponents/Text";
import "./TaskList.css";
import { StyledFlexConteiner } from "../../../Components/StyledComponents/Containers";
import { TaskListSearcher } from "./TaskListComponents/TaskListSearcher";
import { TaskListFilter } from "./TaskListComponents/TaskListFilter";
import { LittleTaskCardDTO } from "./DTO/LittleTaskCardDTO";
import { LittleTaskTableRowDTO } from "./DTO/LittleTaskRowDTO";

export const TaskList: React.FC<{}> = observer(() => {
  console.log("render little task");
  return (
    <>
      <StyledFlexConteiner jc="space-between">
        <div style={{ flex: "1 1 auto" }}>
          <StyledTitleH2>Банк задач</StyledTitleH2>
          <TaskListSearcher />
          {TasksStore.taskListView === TaskListViews.cards && (
            <StyledFlexConteiner
              className="task-list"
              jc="space-evenly"
              align_items="flex-start"
            >
              {TasksStore.searchedAndFilteredAndSortedTasks.map(
                (task: TaskResponseApi) => (
                  <LittleTaskCard taskData={new LittleTaskCardDTO(task)} />
                )
              )}
            </StyledFlexConteiner>
          )}
          {TasksStore.taskListView === TaskListViews.table && (
            <table className="task-table">
              <thead>
                <tr>
                  <th>Название</th>
                  <th>Статус</th>
                  <th>Тип</th>
                  <th>Дата создания</th>
                  <th>Дата изменения</th>
                  <th>Модули</th>
                </tr>
              </thead>
              <tbody>
                {TasksStore.searchedAndFilteredAndSortedTasks.map(
                  (task: TaskResponseApi) => (
                    <LittleTaskTableRow
                      taskData={new LittleTaskTableRowDTO(task)}
                    />
                  )
                )}
              </tbody>
            </table>
          )}
        </div>
        <TaskListFilter />
      </StyledFlexConteiner>
    </>
  );
});
