import { observer } from "mobx-react";
import React from "react";
import { TagStore } from "../../../Stores/TagStore";
import { DialogModal } from "../../../Components/DialogModal";
import "../../../Components/TagPicker.css";
import {
  StyledDefaultButton,
  StyledPrimaryButton,
} from "../../../Components/StyledComponents/Buttons";
import { StyledInput } from "../../../Components/StyledComponents/Input";
import { StyledLabel } from "../../../Components/StyledComponents/Label";
import { StyledSelect } from "../../../Components/StyledComponents/Select";

const TagsSelect: React.FC<{ tagsStore: TagStore }> = observer(
  ({ tagsStore }) => {
    return (
      <StyledSelect onChange={tagsStore.onChangeDeletedTagId}>
        <option
          id={""}
          value={""}
          disabled
          selected
          hidden
        >
          Выберите тег
        </option>
        {tagsStore.tags.map((tag) => {
          return (
            <option key={tag.tagId} id={tag.tagId}>
              {tag.tagName}
            </option>
          );
        })}
      </StyledSelect>
    );
  }
);

export const ManageTagsModal: React.FC<{
  tagsStore: TagStore;
}> = observer(({ tagsStore }) => {
  return (
    <DialogModal onCloseClick={tagsStore.onManageModalClose}>
      <DialogModal.Header>Управление тегами</DialogModal.Header>
      <DialogModal.Body>
        <form>
          <div>
            <StyledLabel>Удалить тег</StyledLabel>
            <TagsSelect tagsStore={tagsStore} />
          </div>
          <StyledPrimaryButton onClick={tagsStore.deleteTag}>
            Удалить
          </StyledPrimaryButton>
        </form>
      </DialogModal.Body>

      <DialogModal.Body>
        <form>
          <div>
            <StyledLabel>Добавить тег</StyledLabel>
            <StyledInput
              placeholder="Введите тег"
              onChange={tagsStore.onChangeNewTagName}
            ></StyledInput>
          </div>
          <StyledPrimaryButton onClick={tagsStore.addTag}>
            Добавить
          </StyledPrimaryButton>
        </form>
      </DialogModal.Body>
      <DialogModal.Footer>
        <StyledDefaultButton onClick={tagsStore.onManageModalClose}>
          Отмена
        </StyledDefaultButton>
      </DialogModal.Footer>
    </DialogModal>
  );
});
