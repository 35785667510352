import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { Tag } from "../DTO/ServerResponseType/Tag";
import TagSender from "../SendersСlientRequests/TagSender";

export class TagStore {
  public tags: IObservableArray<Tag> = observable.array([]);
  public addedTagName: string = "";
  public showManageTags: boolean = false;

  deletedTagId: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  public onManageModalOpen = () => {
    this.showManageTags = true;
  };

  public onManageModalClose = () => {
    this.showManageTags = false;
  };

  public addTag = () => {
    TagSender.sendToCreateTag(this.addedTagName)
      .then((tagId: string) => {
        this.tags.push({ tagId: tagId, tagName: this.addedTagName });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  public deleteTag = () => {
    if (this.deletedTagId) {
      TagSender.sendToDeleteTag(this.deletedTagId)
        .then(() => {
          this.tags.replace(
            this.tags.filter((tag: Tag) => tag.tagId !== this.deletedTagId)
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("Выберите тег для удаления");
    }
  };

  onChangeDeletedTagId = (event: React.FormEvent<HTMLSelectElement>) => {
    this.deletedTagId =
      event.currentTarget.options[event.currentTarget.selectedIndex].id;
  };

  onChangeNewTagName = (event: React.FormEvent<HTMLInputElement>) => {
    this.addedTagName = event.currentTarget.value;
  };
}

export default new TagStore();
