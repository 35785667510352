import { observer } from "mobx-react";
import { NewsStoreApi } from "../../Stores/NewsStore";
import { DialogModal } from "../../Components/DialogModal";
import {
  StyledDefaultButton,
  StyledPrimaryButton,
} from "../../Components/StyledComponents/Buttons";
import { StyledFlexConteiner } from "../../Components/StyledComponents/Containers";
import React from "react";

export const DeleteNewsModal: React.FC<{
  newsStore: NewsStoreApi;
}> = observer(({ newsStore }) => {
  return (
    <DialogModal onCloseClick={newsStore.onDeleteModalClose}>
      <DialogModal.Header>Удаление новости</DialogModal.Header>
      <DialogModal.Body>
        <p>Вы уверены, что хотите удалить новость?</p>
        <p>Заголовок удаляемой новости:</p>
        <h3> {newsStore.deleteNewsData.newsTitle} </h3>
      </DialogModal.Body>

      <DialogModal.Footer>
        <StyledFlexConteiner jc="space-evenly" width="100%">
          <StyledPrimaryButton
            onClick={() => {
              newsStore.deleteNewsItem(newsStore.deleteNewsData.newsId);
              newsStore.onDeleteModalClose();
            }}
          >
            Удалить
          </StyledPrimaryButton>
          <StyledDefaultButton onClick={newsStore.onDeleteModalClose}>
            Отмена
          </StyledDefaultButton>
        </StyledFlexConteiner>
      </DialogModal.Footer>
    </DialogModal>
  );
});
