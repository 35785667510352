import { observer } from "mobx-react";
import "./App.css";
import store from "./Stores/MainPageStore";
import { RegistrationForm } from "./MainWindow/RegistrationForm";
import { LoginForm } from "./MainWindow/LoginForm";
import { StudentLoginForm } from "./MainWindow/StudentLoginForm";
import { StyledDefaultButton } from "./Components/StyledComponents/Buttons";
import { TopWaveForMainWindow } from "./Components/Waves";
import { Banner } from "./MainWindow/Banner";
import { HelloProjectInfo } from "./MainWindow/Project";
import {
  ConteinerBg,
  StyledFlexConteiner,
} from "./Components/StyledComponents/Containers";
import { InfoForUsers } from "./MainWindow/InfoForUsers";
import { CatButton } from "./MainWindow/CatButton";
import { Content } from "./Components/Content";
import {
  StyledSubTitleH1,
  StyledTitleH1WithSubTitle,
} from "./Components/StyledComponents/Text";

const App = () => {
  console.log("render app");
  return (
    <Content withTopWave={false}>
      <StyledFlexConteiner
        jc="space-evenly"
        bg={ConteinerBg.dust}
        padding="0px 0px 50px 0px"
      >
        <Banner />
        <div>
          <TopWaveForMainWindow />
          <HelloProjectInfo />
          <div className="auth--options">
            <StyledDefaultButton onClick={store.onStudentLoginOpen}>
              Вход для детей
            </StyledDefaultButton>
            <StyledDefaultButton onClick={store.onUserLoginOpen}>
              Вход для взрослых
            </StyledDefaultButton>
            <StyledDefaultButton onClick={store.onRegistrationOpen}>
              Регистрация
            </StyledDefaultButton>
          </div>
          {store.showRegistration && <RegistrationForm store={store} />}
          {store.showUserLogin && <LoginForm store={store} />}
          {store.showStudentLogin && <StudentLoginForm store={store} />}
        </div>
      </StyledFlexConteiner>

      <StyledTitleH1WithSubTitle>
        Задача =&gt; Алгоритм =&gt; Код
      </StyledTitleH1WithSubTitle>
      <StyledSubTitleH1>
        Интерактивные задачи по программированию 6+
      </StyledSubTitleH1>

      <StyledFlexConteiner jc="space-evenly" height="610px" margin="50px 0px">
        <CatButton
          href={"paint/demo.html"}
          img={{
            src: "https://kotobot.app/img/kraski_2.svg",
          }}
          text={{
            header:
              "Ты любишь рисовать красками? Отлично! А у меня вместо красок... код!",
            body: "Я смогу нарисовать любую картинку, если ты поможешь мне - напишешь \
          программу! Изучи мой язык, и у тебя все обязательно получится!",
            footer: "Выбирай картинку и давай рисовать!",
          }}
        />
        <img src="/img/yzor1.svg" />
        <CatButton
          href={"pen/demo.html"}
          img={{
            src: "https://kotobot.app/img/karandash_2.svg",
          }}
          text={{
            header:
              "Ты можешь нарисовать дом, жирафа или подъемный кран? Здорово!",
            body: "Я тоже смогу, если ты напишешь программу с помощью моих команд. \
            Здесь важно не отвлекаться и делать работу аккуратно шаг за шагом.",
            footer: "Изучи мои новые команды и вперед!",
          }}
        />
        <img src="/img/yzor2.svg" />
        <CatButton
          href={"bot/demo.html"}
          img={{
            src: "https://kotobot.app/img/Kubiki_2.svg",
          }}
          text={{
            header:
              "Ты любить решать сложные задачки? Тогда смело открывай этот раздел!",
            body: "Я приготовил для тебя много разных заданий от совсем несложных до тех, \
            где надо хорошенько подумать. Я помогу тебе освоить нужные команды. \
            Код становится сложнее, а результат интереснее!",
            footer: "Дерзай!",
          }}
        />
      </StyledFlexConteiner>

      <InfoForUsers
        title={"Родителям"}
        img={{ src: "/img/parents.svg", side: "left" }}
        text={
          "Вы хотите научить детей программировать, не выходя из дома? Тогда нам \
          по пути! Котобот научит детей основам программирования в игровой форме. \
          Ваш ребенок будет писать настоящий программный код, выполняя несложные инструкции. \
          У Котобота есть множество различных задач и несколько режимов исполнения, \
          вы с легкостью подберете интересные задания именно для вашего ребенка. \
          Ознакомиться с задачами можно уже сейчас."
        }
      />
      <InfoForUsers
        title={"Педагогам"}
        img={{ src: "/img/teachers.svg", side: "right" }}
        text={
          "Котобот - результат накопленных знаний и подходов к обучению детей \
          информатике и основам программирования. Вместе с Котоботом дети \
          развивают алгоритмическое мышление, учатся писать программы. \
          Язык исполнителя достаточно простой и доступный даже младшим школьникам. \
          Для малышей от 5 до 7 лет предусмотрена возможность облегченного написания программ. \
          Вместе с тем структуры алгоритмических конструкций приближены к современным языкам программирования."
        }
      />
    </Content>
  );
};

export default observer(App);
