import styled from "styled-components";

export const StyledTitleH1 = styled.h1`
  font-family: "Amatic SC";
  font-size: 56pt;
  text-align: center;
  margin: 50px auto;
`;

export const StyledLittleTitleH1 = styled(StyledTitleH1)`
  font-size: 28pt;
`;

export const StyledTitleH1WithSubTitle = styled(StyledTitleH1)`
  margin: 50px auto 0px;
`;

export const StyledSubTitleH1 = styled(StyledLittleTitleH1)`
  font-family: "Amatic SC", cursive;
  margin: 0px auto;
`;

export const StyledTitleH2 = styled.h2`
  font-family: "Amatic SC", cursive;
  font-size: 42pt;
  text-align: center;
  margin: 20px;
`;
