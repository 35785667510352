import { Tag } from "../../../../DTO/ServerResponseType/Tag";
import { TaskResponseApi } from "../../../../DTO/ServerResponseType/Task";
import { LittleTaskCardApi } from "./LittleTaskAPI";
import { LittleTaskDTO } from "./LittleTaskDTO";

export class LittleTaskCardDTO
  extends LittleTaskDTO
  implements LittleTaskCardApi
{
  taskPrivateTitle: string;
  taskIllustrationSrc: string;
  taskTags: Tag[];

  constructor(taskData: TaskResponseApi) {
    super(taskData);
    this.taskPrivateTitle = taskData.taskPrivateName;
    this.taskIllustrationSrc = taskData.taskIllustration
      ? "data:image/png;base64," + taskData.taskIllustration.illustration
      : "";
    this.taskTags = taskData.taskTags.map((taskTag) => taskTag.tag);
  }
}
