import styled from "styled-components";
import { ConteinerBg } from "./Containers";

export const StyledSelect = styled.select`
  flex-grow: 1;
  margin: 5px;
  padding: 3px;
  border: solid 1px;
  border-color: ${ConteinerBg.black};
  border-radius: 5px;
`;

