import { StyledFlexConteiner } from "../../../../Components/StyledComponents/Containers";
import { NavLink } from "react-router-dom";
import { StyledFullImg } from "../../../../Components/StyledComponents/Image";

import { StyledDefaultButton } from "../../../../Components/StyledComponents/Buttons";
import { StyledCard } from "../../../../Components/StyledComponents/Card";
import { LittleTaskHead } from "./LittleTaskComponents/LittleTaskHead";
import "./LittleTaskCard.css";
import dictionary from "../../../../langs.json";
import { LittleTaskTags } from "./LittleTaskComponents/LittleTaskTags";
import { LittleTaskCardApi, LittleTaskTableRowApi } from "../DTO/LittleTaskAPI";
import { LittleTaskModules } from "./LittleTaskComponents/LittleTaskModules";

const TaskDataTextItem: React.FC<{ nameItem: string; dataItem: any }> = (
  props
) => {
  return (
    <p>
      <b>{props.nameItem + ": "}</b>
      {props.dataItem}
    </p>
  );
};

export const LittleTaskCard: React.FC<{ taskData: LittleTaskCardApi }> = ({
  taskData,
}) => {
  return (
    <StyledCard className="task-card" key={taskData.taskId}>
      <NavLink to={`/task/one/${taskData.taskId}`}>
        <StyledFlexConteiner jc="space-between" flex_direction="column">
          {taskData.taskIllustrationSrc !== "" && (
            <StyledFullImg src={taskData.taskIllustrationSrc} />
          )}
          <div style={{ padding: "10px" }}>
            <LittleTaskHead taskData={taskData} />
            <TaskDataTextItem
              nameItem="Статус"
              dataItem={taskData.taskStatusName}
            />
            <TaskDataTextItem
              nameItem="Тип"
              dataItem={
                dictionary.ru.translation[
                  taskData.taskTypeName as keyof typeof dictionary.ru.translation
                ]
              }
            />
            <TaskDataTextItem
              nameItem="Текущие модули"
              dataItem={<LittleTaskModules taskData={taskData} />}
            />
            <LittleTaskTags taskData={taskData} />
            <StyledDefaultButton style={{ margin: "auto" }}>
              Назначить в модуль
            </StyledDefaultButton>
          </div>
        </StyledFlexConteiner>
      </NavLink>
    </StyledCard>
  );
};

export const LittleTaskTableRow: React.FC<{
  taskData: LittleTaskTableRowApi;
}> = ({ taskData }) => {
  return (
    <tr key={taskData.taskId}>
      <td>{taskData.taskPublicTitle}</td>
      <td className="col-nowrap text-align-center">
        {taskData.taskStatusName}
      </td>
      <td className="col-nowrap text-align-center">
        {
          dictionary.ru.translation[
            taskData.taskTypeName as keyof typeof dictionary.ru.translation
          ]
        }
      </td>
      <td className="col-nowrap text-align-center">
        {taskData.taskCreationTime}
      </td>
      <td className="col-nowrap text-align-center">
        {taskData.taskModificationTime}
      </td>
      <td className="text-align-center">
        <LittleTaskModules taskData={taskData} />
      </td>
    </tr>
  );
};
