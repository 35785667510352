import React from "react";
import { observer } from "mobx-react";
import { MainPageStore } from "../Stores/MainPageStore";
import { StyledPrimaryButton } from "../Components/StyledComponents/Buttons";
import { DialogModal } from "../Components/DialogModal";

export const RegistrationForm: React.FC<{ store: MainPageStore }> = observer(
  ({ store }) => {
    let closeRegistrationAndShowUserLogin = function () {
      store.onRegistrationClose();
      store.onUserLoginOpen();
    };

    return (
      <DialogModal onCloseClick={store.onRegistrationClose}>
        <DialogModal.Header>Регистрация</DialogModal.Header>

        <DialogModal.Body>
          <form action="" method="post">
            <div>
              <input type="text" placeholder="Имя" className="login" required />
            </div>
            <div>
              <input type="email" placeholder="Почта" required />
            </div>
            <div>
              <input type="password" placeholder="Пароль" required />
            </div>
            <div>
              <input type="password" placeholder="Повторите пароль" required />
            </div>
            <div className="block">
              <StyledPrimaryButton
                onClick={function () {
                  throw new Error("Function not implemented.");
                }}
              >
                Регистрация
              </StyledPrimaryButton>
            </div>
          </form>
        </DialogModal.Body>

        <DialogModal.Footer>
          <a onClick={closeRegistrationAndShowUserLogin}>Войти</a>
        </DialogModal.Footer>
      </DialogModal>
    );
  }
);
