import { Link, NavLink } from "react-router-dom";
import { StyledConteiner, StyledFlexConteiner } from "./StyledComponents/Containers";
import "./Waves.css";
import styled from "styled-components";
import { Logo, StyledLogo } from "./Logo";
import * as React from "react";

const StyledTopWave = styled.div.attrs(() => ({
  className: "wave",
}))`
  ${{ backgroundImage: `url(/img/volna.svg)` }};
  justify-content: space-between;
  ${StyledLogo} {
    margin-left: 30px;
    width: auto;
    img {
      height: 70px;
    }
  }
`;

const NavBar: React.FC<{}> = () => {
  return (
    <>
      <NavLinkDefault to="/" text="Главная" />
      <NavLinkDefault to="/news/list" text="Новости" />
      <a href="lessons.html">Занятия</a>
      <a href="competition.html">Конкурс</a>
      <a href="index-en.html">EN</a>
      <a href="contacts.html">
        <img src="/img/icons/mail.svg" />
      </a>
    </>
  );
};

export const TopWaveDefault: React.FC<{}> = () => {
  return (
    <StyledTopWave>
      <Logo></Logo>

      <div>
        <NavBar />
      </div>
    </StyledTopWave>
  );
};

const StyledTopWaveForMainWindow = styled(StyledTopWave)`
  left: -80px;
  padding-left: 80px;
  justify-content: space-evenly;
  position: relative;
  z-index: 1;
`;

const NavLinkDefault: React.FC<{ to: string; text: string }> = (props) => {
  return (
    <NavLink
      to={props.to}
      style={({ isActive, isPending }) => {
        return {
          display: isActive ? "none" : "block",
        };
      }}
    >
      {props.text}
    </NavLink>
  );
};

export const TopWaveForMainWindow: React.FC<{}> = ({}) => {
  return (
    <StyledTopWaveForMainWindow>
      <NavBar />
    </StyledTopWaveForMainWindow>
  );
};

const StyledBottomWave = styled.div.attrs(() => ({
  className: "footer",
}))`
  ${StyledConteiner} {
    padding: 25.5px;
    height: 50px;
    ${{ backgroundImage: `url(/img/volna_blue_1.svg)` }};
  }
`;

export const BottomWave: React.FC<{}> = () => {
  return (
    <StyledBottomWave>
      <div className="pattern">
        <img src="/img/pattern.svg" />
      </div>
      <StyledFlexConteiner>
        <div className="copyright">
          &#169; Дедова Лариса, 2018 - 2022 &nbsp;
          <a href="contacts.html">
            <img
              width="24px"
              style={{ verticalAlign: "text-bottom" }}
              src="/img/icons/mail_white.svg"
            />
          </a>
          <br />
          Дизайн: Наталья Новикова
        </div>
        <div>
          Любое использование либо копирование материалов или подборки
          материалов сайта, иллюстраций, элементов дизайна и оформления
          допускается лишь с разрешения правообладателя и только со ссылкой на
          источник: <Link to="/">kotobot.app</Link>
        </div>
      </StyledFlexConteiner>
    </StyledBottomWave>
  );
};
