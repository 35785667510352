import React from "react";
import { StyledIconDefaultButton } from "./StyledComponents/Buttons";

interface ButtonIconPropsApi {
  id?: string;
  icon: string;
  alt: string;
  onClick?: (event: any) => any;
  height?: string;
  width?: string;
}

export const IconDefaultButton: React.FC<ButtonIconPropsApi> = (
  props: ButtonIconPropsApi
) => {
  return (
    <StyledIconDefaultButton
      height={props.height}
      width={props.width}
      id={props.id}
      onClick={props.onClick}
    >
      <img src={props.icon} alt={props.alt} />
    </StyledIconDefaultButton>
  );
};

interface ButtonClickPropsApi {
  id?: string;
  onClick?: (event: any) => any;
}

export const UpdateDefaultButton: React.FC<ButtonClickPropsApi> = (
  props: ButtonClickPropsApi
) => {
  return (
    <IconDefaultButton
      onClick={props.onClick}
      icon="/img/pen.svg"
      alt="Изменить"
    />
  );
};

export const DeleteDefaultButton: React.FC<ButtonClickPropsApi> = (
  props: ButtonClickPropsApi
) => {
  return (
    <IconDefaultButton
      onClick={props.onClick}
      icon="/img/trash.svg"
      alt="Удалить"
    />
  );
};

export const ShowDefaultButton: React.FC<ButtonClickPropsApi> = (
  props: ButtonClickPropsApi
) => {
  return (
    <IconDefaultButton
      height="30px"
      width="30px"
      id={props.id}
      onClick={props.onClick}
      icon="/img/arrow-down.svg"
      alt="Расскрыть"
    />
  );
};

export const CloseDefaultButton: React.FC<ButtonClickPropsApi> = (
  props: ButtonClickPropsApi
) => {
  return (
    <IconDefaultButton
      height="30px"
      width="30px"
      id={props.id}
      onClick={props.onClick}
      icon="/img/arrow-top.svg"
      alt="Свернуть"
    />
  );
};
