// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text {
  padding: 0 50px 0 50px;
  line-height: 1.5em;
}

.placeholder {
  width: 400px;
  height: 200px;
}

.placeholder img {
  position: relative;
  height: 280px;
  top: -70px;
  max-width: 400px;
}
`, "",{"version":3,"sources":["webpack://./src/MainWindow/InfoForUsers.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".text {\n  padding: 0 50px 0 50px;\n  line-height: 1.5em;\n}\n\n.placeholder {\n  width: 400px;\n  height: 200px;\n}\n\n.placeholder img {\n  position: relative;\n  height: 280px;\n  top: -70px;\n  max-width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
