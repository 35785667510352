import "./Buttons.css";
import { styled } from "styled-components";

export const StyledDefaultButton = styled.button.attrs(() => ({
  className: "custom-button default-button",
}))``;

export const StyledPrimaryButton = styled.button.attrs(() => ({
  className: "custom-button primary-button",
}))``;

export const StyledScrollButton = styled(StyledDefaultButton)<{
  height?: string;
}>`
  height: ${({ height = "50px" }) => height};
  width: auto;
  margin: 0px;
`;

export const StyledPrimaryRightMarginButton = styled(StyledPrimaryButton)`
  margin: 0px 20px 0px auto;
`;

export const StyledIconDefaultButton = styled(StyledDefaultButton)<{
  height?: string;
  width?: string;
}>`
  height: ${({ height = "40px" }) => height};
  width: ${({ width = "40px" }) => width};
  margin: 5px;
  padding: 5px;
  border: 0px;
  box-shadow: 0;
  display: flex;

  img {
    height: 90%;
    margin: auto;
  }
`;
