import { action, makeObservable, observable } from "mobx";

export class MainPageStore {
    public showRegistration = false;
    public showUserLogin = false;
    public showStudentLogin = false;

    constructor() {
        makeObservable(this, {
          showRegistration: observable,
          onRegistrationOpen: action.bound,
          showUserLogin: observable,
          onUserLoginOpen: action.bound,
          showStudentLogin: observable,
          onStudentLoginOpen: action.bound,
        });
      }

    public onRegistrationOpen = () => {
        this.showRegistration = true;
    }

    public onRegistrationClose = () => {
        this.showRegistration = false;
    }

    public onUserLoginOpen = () => {
        this.showUserLogin = true;
    }

    public onUserLoginClose = () => {
        this.showUserLogin = false;
    }

    public onStudentLoginOpen = () => {
        this.showStudentLogin = true;
    }

    public onStudentLoginClose = () => {
        this.showStudentLogin = false;
    }
}

export default new MainPageStore();