// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-card {
  width: 300px;
  max-width: 300px;
  color: black;
  margin: 40px 0px;
}

.task-card h1,
h2 {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.task-card-tags {
  border: 1px solid #1a6a7c;
  border-radius: 15px;
}

.task-card-tags-p {
  margin: auto;
  width: 70%;
  text-overflow: ellipsis;
}

.task-table {
  margin: 40px 0px;
  width: 100%;
  border: 2px solid;
  border-radius: 10px;
  overflow: hidden;
}

.task-table thead {
  background-color: #1a6a7c;
  color: white;
  text-align: center;
}

.task-table th, td {
  padding: 10px;
  border: 0px;
  border: 1px solid;
}

.task-table td:last-child, td:first-child {
  width: 30%;
}

.task-table .col-nowrap {
  white-space: nowrap;
}

.task-table .text-align-center {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/ProfileWindow/AdminWindows/TaskListWindow/TaskListComponents/LittleTaskCard.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;;EAEE,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".task-card {\n  width: 300px;\n  max-width: 300px;\n  color: black;\n  margin: 40px 0px;\n}\n\n.task-card h1,\nh2 {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.task-card-tags {\n  border: 1px solid #1a6a7c;\n  border-radius: 15px;\n}\n\n.task-card-tags-p {\n  margin: auto;\n  width: 70%;\n  text-overflow: ellipsis;\n}\n\n.task-table {\n  margin: 40px 0px;\n  width: 100%;\n  border: 2px solid;\n  border-radius: 10px;\n  overflow: hidden;\n}\n\n.task-table thead {\n  background-color: #1a6a7c;\n  color: white;\n  text-align: center;\n}\n\n.task-table th, td {\n  padding: 10px;\n  border: 0px;\n  border: 1px solid;\n}\n\n.task-table td:last-child, td:first-child {\n  width: 30%;\n}\n\n.task-table .col-nowrap {\n  white-space: nowrap;\n}\n\n.task-table .text-align-center {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
