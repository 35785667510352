// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.images-div {
  position: relative;
}

.images-div .dark {
  position: absolute;
  opacity: 0.3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  background-color: #000;
  display: flex;
  color: aliceblue;
}

.images-div .dark p{
  margin: auto;
}

.panel {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 810px;
}

.panel-item {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  margin: 0px 10px;
}

.panel-item .img-div {
  height: 200px;
  width: 250px;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/ProfileWindow/AdminWindows/NewsEditWindow/NewsCreateWindow.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,WAAW;EACX,sBAAsB;EACtB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,6BAA6B;EAC7B,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".images-div {\n  position: relative;\n}\n\n.images-div .dark {\n  position: absolute;\n  opacity: 0.3;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 50;\n  background-color: #000;\n  display: flex;\n  color: aliceblue;\n}\n\n.images-div .dark p{\n  margin: auto;\n}\n\n.panel {\n  display: flex;\n  overflow: hidden;\n  position: relative;\n  width: 810px;\n}\n\n.panel-item {\n  height: 100%;\n  display: flex;\n  justify-content: space-evenly;\n  flex-direction: column;\n  margin: 0px 10px;\n}\n\n.panel-item .img-div {\n  height: 200px;\n  width: 250px;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
