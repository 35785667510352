import { NewsImageResponse } from "../DTO/ServerResponseType/NewsItem";
import { StyledFullImg } from "./StyledComponents/Image";

export const FullNewsImage: React.FC<{
  newsImage: NewsImageResponse;
  onClick?: () => void;
}> = ({ newsImage, onClick }) => {
  let displayNewsImage =
    newsImage === undefined || newsImage === null
      ? { id: "1", src: "/img/kotobot_img_not_exists.jpeg" }
      : {
          id: newsImage.newsImageId,
          src: "data:image/png;base64," + newsImage.image,
        };

  return (
    <StyledFullImg
      key={displayNewsImage.id}
      src={displayNewsImage.src}
      onClick={onClick}
    ></StyledFullImg>
  );
};
