import { observer } from "mobx-react";
import React from "react";
import { MainPageStore } from "../Stores/MainPageStore";
import { DialogModal } from "../Components/DialogModal";
import { StyledPrimaryButton } from "../Components/StyledComponents/Buttons";

export const StudentLoginForm: React.FC<{ store: MainPageStore }> = observer(
  ({ store }) => {
    return (
      <DialogModal onCloseClick={store.onStudentLoginClose}>
        <DialogModal.Header>Авторизация</DialogModal.Header>
        <DialogModal.Body>
          <form action="" method="post">
            <div>
              <input type="text" placeholder="Имя" required />
            </div>
            <div>
              <input type="password" placeholder="Пароль" required />
            </div>
            <div>
              <input type="text" placeholder="Код группы" required />
            </div>
            <div>
              <StyledPrimaryButton
                onClick={function () {
                  throw new Error("Function not implemented.");
                }}
              >
                Войти
              </StyledPrimaryButton>
            </div>
          </form>
        </DialogModal.Body>
        <DialogModal.Footer>
          <a href="#">Регистрация</a>
          <a href="#">Забыли пароль?</a>
        </DialogModal.Footer>
      </DialogModal>
    );
  }
);
