import { StyledConteiner, StyledFlexConteiner } from "../Components/StyledComponents/Containers";
import { StyledTitleH2 } from "../Components/StyledComponents/Text";
import "./InfoForUsers.css";

interface InfoForUsersApi {
  title: string;
  img: { src: string; side: string };
  text: string;
}

export const InfoForUsers: React.FC<InfoForUsersApi> = (props) => {
  return (
    <StyledConteiner margin="80px 0px">
      <StyledTitleH2>{props.title}</StyledTitleH2>
      <StyledFlexConteiner jc="space-evenly">
        {props.img.side == "left" && (
          <div className="text">
            <div className="placeholder">
              <img src={props.img.src} />
            </div>
          </div>
        )}
        <div className="text">{props.text}</div>
        {props.img.side == "right" && (
          <div className="text">
            <div className="placeholder">
              <img src={props.img.src} />
            </div>
          </div>
        )}
      </StyledFlexConteiner>
    </StyledConteiner>
  );
};
