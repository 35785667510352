import {
  StyledDefaultButton,
  StyledPrimaryButton,
} from "../../Components/StyledComponents/Buttons";
import { StyledFlexConteiner } from "../../Components/StyledComponents/Containers";
import { NavLink } from "react-router-dom";
import NewsStore, { NewsStoreApi } from "../../Stores/NewsStore";
import { observer } from "mobx-react";
import { StyledDateSpan } from "../../Components/StyledComponents/Span";
import { FullNewsImage } from "../../Components/Image";
import { NewsImageResponse } from "../../DTO/ServerResponseType/NewsItem";
import { StyledCard } from "../../Components/StyledComponents/Card";
import "./LittleNews.css";

export interface LittleNewsApi {
  newsId: string;
  newsTitle: string;
  newsImage: NewsImageResponse;
  newsPreviewText: string;
  newsDate: string;
}

export const LittleNewsData: React.FC<{
  newsData: LittleNewsApi;
}> = (props) => {
  return (
    <StyledFlexConteiner jc="start" key={props.newsData.newsId}>
      <div className="newsPic">
        <FullNewsImage newsImage={props.newsData.newsImage} />
      </div>
      <StyledFlexConteiner
        flex_direction="column"
        jc="space-evenly"
        align_items="start"
        className="newsData"
      >
        <StyledDateSpan>{props.newsData.newsDate}</StyledDateSpan>
        <h1>{props.newsData.newsTitle}</h1>
        <p>{props.newsData.newsPreviewText}</p>
        <StyledPrimaryButton>Подробнее</StyledPrimaryButton>
      </StyledFlexConteiner>
    </StyledFlexConteiner>
  );
};

type LittleNewsProps = {
  newsData: LittleNewsApi;
  newsStore: NewsStoreApi;
  isDebugMode?: boolean;
};

export const LittleNews: React.FC<LittleNewsProps> = observer(
  (props: LittleNewsProps) => {
    if (props.isDebugMode === undefined) props.isDebugMode = false;

    return (
      <StyledCard className="news-card">
        <NavLink to={`/news/one/${props.newsData.newsId}`}>
          <StyledFlexConteiner
            jc="space-between"
            key={props.newsData.newsId}
            padding="30px"
          >
            <LittleNewsData newsData={props.newsData} />
            {props.isDebugMode && (
              <div className="newsButtons">
                <NavLink to={`/admin/news/update/${props.newsData.newsId}`}>
                  <StyledDefaultButton>Изменить новость</StyledDefaultButton>
                </NavLink>
                <StyledDefaultButton
                  onClick={(event) => {
                    event.preventDefault();
                    NewsStore.onDeleteModalOpen(props.newsData);
                  }}
                >
                  Удалить новость
                </StyledDefaultButton>
              </div>
            )}
          </StyledFlexConteiner>
        </NavLink>
      </StyledCard>
    );
  }
);
