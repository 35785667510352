import { StyledPrimaryButton } from "../../../../Components/StyledComponents/Buttons";
import { StyledFlexConteiner } from "../../../../Components/StyledComponents/Containers";
import { StyledInputWithBorder } from "../../../../Components/StyledComponents/Input";
import { StyledSelect } from "../../../../Components/StyledComponents/Select";
import { SortOptionsForTaskList } from "../../../../Enums/TaskStoreOptions";
import TasksStore from "../../../../Stores/TasksStore";
import { TaskSortMap } from "../../../../utils/sort/taskListSort/options";
import "./TaskListSearcher.css";

const SelectSortedValues: React.FC<{}> = () => {
  let selectOprions: React.DetailedHTMLProps<
    React.OptionHTMLAttributes<HTMLOptionElement>,
    HTMLOptionElement
  >[] = [];

  TaskSortMap.forEach((options, key) =>
    selectOprions.push(
      <option key={key} value={key}>
        {options.text}
      </option>
    )
  );

  return <>{selectOprions.map((option) => option)}</>;
};

export const TaskListSearcher: React.FC<{}> = () => {
  return (
    <>
      <StyledFlexConteiner jc="space-between" className="div-searcher">
        <StyledInputWithBorder
          className="search"
          onChange={TasksStore.onChangeSearchTaskNamePattern}
          placeholder="Поиск"
        ></StyledInputWithBorder>
        <StyledSelect
          key="taskSorter"
          defaultValue={SortOptionsForTaskList.modificationTimeDesc}
          onChange={TasksStore.onChangeSortedOptions}
        >
          <SelectSortedValues></SelectSortedValues>
        </StyledSelect>
        <StyledPrimaryButton onClick={TasksStore.onChangeTaskView}>
          {TasksStore.taskViewButtonText}
        </StyledPrimaryButton>
      </StyledFlexConteiner>
      <p className="p-searcher">
        Найдено задач: {TasksStore.searchedAndFilteredAndSortedTasks.length}
        шт.
      </p>
    </>
  );
};
