// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div-searcher > * {
  margin: 10px !important;
}

.div-searcher .search {
  flex-grow: 2;
}

.p-searcher {
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/ProfileWindow/AdminWindows/TaskListWindow/TaskListComponents/TaskListSearcher.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".div-searcher > * {\n  margin: 10px !important;\n}\n\n.div-searcher .search {\n  flex-grow: 2;\n}\n\n.p-searcher {\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
