// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  font-size: 16px;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.082),
    0 6px 20px 0 rgba(0, 0, 0, 0.034);
  overflow: hidden;
  background-color: white;
}

.card h1,
h2,
p {
  margin: 0px;
  overflow: hidden;
}

.card h1,
h2 {
  font-family: "Amatic SC";
}

.card h1 {
  font-size: 2em;
}

.card h2 {
  font-size: 1.8em;
}

.card p {
  margin: 5px 0px;
  font-family: "Manrope";
}
`, "",{"version":3,"sources":["webpack://./src/Components/StyledComponents/Card.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB;EACnB;qCACmC;EACnC,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;;;EAGE,WAAW;EACX,gBAAgB;AAClB;;AAEA;;EAEE,wBAAwB;AAC1B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,sBAAsB;AACxB","sourcesContent":[".card {\n  font-size: 16px;\n  border-radius: 20px;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.082),\n    0 6px 20px 0 rgba(0, 0, 0, 0.034);\n  overflow: hidden;\n  background-color: white;\n}\n\n.card h1,\nh2,\np {\n  margin: 0px;\n  overflow: hidden;\n}\n\n.card h1,\nh2 {\n  font-family: \"Amatic SC\";\n}\n\n.card h1 {\n  font-size: 2em;\n}\n\n.card h2 {\n  font-size: 1.8em;\n}\n\n.card p {\n  margin: 5px 0px;\n  font-family: \"Manrope\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
