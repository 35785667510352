import { ReactNode } from "react";
import "./Content.css";
import { BottomWave, TopWaveDefault } from "./Waves";
import styled from "styled-components";

const ContentBg = {
  none: "none",
  dust: "url(/img/dust_scratches.png)",
};

interface StyledContentProps {
  bg: string;
  width?: string;
}

const StyledContent = styled.div.attrs(() => ({
  className: "content",
}))<StyledContentProps>`
  background-image: ${({ bg = "none" }) => bg};
  width: ${({ width = "1280px" }) => width};
`;

export const Content: React.FC<{
  children: ReactNode;
  withTopWave?: boolean;
  withBottomWave?: boolean;
  isBlack?: boolean;
  width?: string;
}> = ({
  children,
  withTopWave = true,
  withBottomWave = true,
  isBlack = false,
  width,
}) => {
  return (
    <StyledContent bg={isBlack ? ContentBg.dust : ContentBg.none} width={width}>
      {withTopWave && <TopWaveDefault></TopWaveDefault>}
      {children}
      {withBottomWave && <BottomWave></BottomWave>}
    </StyledContent>
  );
};
