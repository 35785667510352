import {
  ReactNode,
} from "react";
import "./DialogModal.css";

export const DialogModal = (props: {
  children: ReactNode;
  onCloseClick: () => any;
}) => {
  return (
    <>
      <div className="modal--backdrop" onClick={props.onCloseClick}></div>
      <div className="modal">{props.children}</div>
    </>
  );
};

const DialogModalHeader: React.FC<{ children: string }> = (props: {
  children: string;
}) => {
  return <div className="modal--header">{props.children}</div>;
};

const DialogModalBody: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="modal--body">{children}</div>;
};


const DialogModalFooter: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="modal--footer">{children}</div>;
};

DialogModal.Header = DialogModalHeader;
DialogModal.Body = DialogModalBody;
DialogModal.Footer = DialogModalFooter;
