import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, Params, RouterProvider } from "react-router-dom";
import "rsuite/dist/rsuite-no-reset.min.css";
import "./index.css";
import App from "./App";
import ErrorPage from "./Errors/error-page";
import reportWebVitals from "./reportWebVitals";
import {
  NewsList,
  NewsListWindow,
} from "./NewsWindows/NewsListWindow/NewsList";
import { MyProfile } from "./ProfileWindow/ProfileWithTopNav";
import { MyProfile2 } from "./ProfileWindow/Profile";
import { NewsCreateWindow } from "./ProfileWindow/AdminWindows/NewsEditWindow/NewsCreateWindow";
import { NewsItemWindow } from "./NewsWindows/NewsItemWindow/NewsItemWindow";
import NewsSender from "./SendersСlientRequests/NewsSender";
import NewsStore from "./Stores/NewsStore";
import { NewsItemResponse } from "./DTO/ServerResponseType/NewsItem";
import { TaskCreateWindow } from "./ProfileWindow/AdminWindows/TaskEditWindow/TaskCreateWindow";
import TaskSender from "./SendersСlientRequests/TaskSender";
import { TaskResponseData } from "./DTO/ServerResponseType/Task";
import TagStore from "./Stores/TagStore";
import { TaskList } from "./ProfileWindow/AdminWindows/TaskListWindow/TaskList";
import TasksStore from "./Stores/TasksStore";
import { Tag } from "./DTO/ServerResponseType/Tag";
import { Type } from "./DTO/ServerResponseType/Type";

const loadAllNews = async ({}) => {
  let newsResponse = await NewsSender.sendToGetAllNews();

  if (newsResponse !== undefined || newsResponse !== null)
    NewsStore.setNews(newsResponse.body);

  return null;
};

const loadNewsItem = async ({ params }: { params: Params<string> }) => {
  let newsItemData = null;
  if (params.newsId !== undefined && params.newsId !== null) {
    newsItemData = await NewsSender.sendToGetNewsItemByNewsItemId(
      params.newsId
    );
  }
  return newsItemData.body;
};

const loadNewsItemWith3OtherNewsItems = async ({
  params,
}: {
  params: Params<string>;
}) => {
  let newsItemWith3NewsItems: NewsItemResponse[] = [];

  newsItemWith3NewsItems.push(await loadNewsItem({ params }));

  let getNewsItemsResponse = await NewsSender.sendToGetAnyNews(4);

  let otherNews: NewsItemResponse[] = [];
  if (getNewsItemsResponse !== null && getNewsItemsResponse !== undefined) {
    otherNews = getNewsItemsResponse.body.filter(
      (newsItem) => newsItem.newsId !== newsItemWith3NewsItems[0].newsId
    );
  }

  newsItemWith3NewsItems = newsItemWith3NewsItems.concat(otherNews.slice(0, 3));

  return newsItemWith3NewsItems;
};

const loadAllTasksWithDataToFilterTasks = async ({}) => {
  let tasksResponse = await TaskSender.sendToGetAllTasks();

  if (tasksResponse !== undefined || tasksResponse !== null)
    TasksStore.setTasks(tasksResponse.body);

  return await loadDataToCreateTask();
};

const loadDataToCreateTask = async (): Promise<{
  allTaskTypes: Type[];
  allTaskTags: Tag[];
}> => {
  let taskDataToCreateResponse =
    await TaskSender.sendToGetTaskTypesAndTaskTags();
  TagStore.tags.replace(taskDataToCreateResponse.body.tags);

  return {
    allTaskTypes: taskDataToCreateResponse.body.types,
    allTaskTags: taskDataToCreateResponse.body.tags,
  };
};

const loadTaskWithDataToCreateTask = async ({
  params,
}: {
  params: Params<string>;
}): Promise<{
  allTaskTypes: Type[];
  allTaskTags: Tag[];
  taskData: TaskResponseData;
}> => {
  let taskData: TaskResponseData;
  if (!params.taskId) throw Error("Укажите id задачи в url");

  let taskDataResponse = await TaskSender.sendToGetTaskByTaskIdAsync(
    params.taskId
  );

  if (!taskDataResponse) throw Error("Нет доступа к задаче");

  taskData = taskDataResponse.body;
  let taskDataToCreate = await loadDataToCreateTask();

  return {
    allTaskTypes: taskDataToCreate.allTaskTypes,
    allTaskTags: taskDataToCreate.allTaskTags,
    taskData: taskData,
  };
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [],
  },
  {
    path: "news/list",
    element: <NewsListWindow />,
    loader: loadAllNews,
  },
  {
    path: "news/one/:newsId",
    element: <NewsItemWindow />,
    loader: loadNewsItemWith3OtherNewsItems,
  },
  {
    path: "admin",
    element: <MyProfile />,
    children: [
      {
        path: "news/create",
        element: <NewsCreateWindow />,
      },
      {
        path: "news/list",
        element: <NewsList isDebugMode={true} />,
        loader: loadAllNews,
      },
      {
        path: "news/update/:newsId",
        element: <NewsCreateWindow />,
        loader: loadNewsItem,
      },
      {
        path: "task/create",
        element: <TaskCreateWindow />,
        loader: loadDataToCreateTask,
      },
      {
        path: "task/update/:taskId",
        element: <TaskCreateWindow />,
        loader: loadTaskWithDataToCreateTask,
        errorElement: <ErrorPage />,
      },
      {
        path: "task/list",
        element: <TaskList />,
        loader: loadAllTasksWithDataToFilterTasks,
      },
    ],
  },
  {
    path: "tutor",
    element: <MyProfile2 />,
    children: [
      {
        path: "news/create",
        element: <NewsCreateWindow />,
      },
      {
        path: "news/list",
        element: <NewsList isDebugMode={true} />,
        loader: loadAllNews,
      },
      {
        path: "news/update/:newsId",
        element: <NewsCreateWindow />,
        loader: loadNewsItem,
      },
      {
        path: "task/create",
        element: <TaskCreateWindow />,
        loader: loadDataToCreateTask,
      },
      {
        path: "task/update/:taskId",
        element: <TaskCreateWindow />,
        loader: loadTaskWithDataToCreateTask,
        errorElement: <ErrorPage />,
      },
      {
        path: "task/list",
        element: <TaskList />,
        loader: loadAllTasksWithDataToFilterTasks,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
