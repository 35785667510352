import React from "react";
import { MainPageStore } from "../Stores/MainPageStore";
import { observer } from "mobx-react";
import { DialogModal } from "../Components/DialogModal";
import { StyledPrimaryButton } from "../Components/StyledComponents/Buttons";

// Todo: change form and add store
export const LoginForm: React.FC<{ store: MainPageStore }> = observer(
  ({ store }) => {
    let closeUserLoginAndShowRegistration = function () {
      store.onRegistrationOpen();
      store.onUserLoginClose();
    };
    return (
      <DialogModal onCloseClick={store.onUserLoginClose}>
        <DialogModal.Header>Авторизация</DialogModal.Header>
        <DialogModal.Body>
          <form action="" method="post">
            <div>
              <input
                type="text"
                placeholder="Эл. почта"
                className="login"
                required
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Пароль"
                className="pass"
                required
              />
            </div>
            <div>
              <StyledPrimaryButton
                onClick={function () {
                  throw new Error("Function not implemented.");
                }}
              >
                Войти
              </StyledPrimaryButton>
            </div>
          </form>
        </DialogModal.Body>

        <DialogModal.Footer>
          <a onClick={closeUserLoginAndShowRegistration}>Регистрация</a>
          <a href="#">Забыли пароль?</a>
        </DialogModal.Footer>
      </DialogModal>
    );
  }
);
