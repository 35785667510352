import { Module } from "../../../../DTO/ServerResponseType/Module";
import { TaskResponseApi } from "../../../../DTO/ServerResponseType/Task";
import { allTaskStatusesWithText } from "../../../../Enums/TaskStatuses";
import { LittleTaskApi } from "./LittleTaskAPI";

export class LittleTaskDTO implements LittleTaskApi {
  taskStatusName: string;
  taskId: string;
  taskPublicTitle: string;
  taskTypeName: string;
  taskModules: Module[];

  constructor(taskData: TaskResponseApi) {
    this.taskId = taskData.taskId!;
    this.taskPublicTitle = taskData.taskPublicName;

    let taskStatusNameOrUndefined = allTaskStatusesWithText.get(
      taskData.taskStatusId
    );
    this.taskStatusName = taskStatusNameOrUndefined
      ? taskStatusNameOrUndefined
      : "Не определен";
    this.taskTypeName = taskData.taskType.typeName;

    this.taskModules = taskData.taskModules.map(
      (taskModule) => taskModule.module
    );
  }
}
