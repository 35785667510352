// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wave {
  /* size */
  width: 100%;
  height: 67px;

  /* children position */
  display: flex;
  padding-top: 5px;
  padding-bottom: 35px;

  /* font */
  font-family: "Amatic SC";
  font-weight: 700;
  font-size: 22pt;

  box-sizing: content-box; 
}

.wave > div {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
}

.wave a {
  margin: 20px 0px;
  height: 37px;
}

.footer {
  max-width: 100%;
  color: white;
}

.copyright {
  width: 600px;
  padding-right: 40px;
}

.pattern {
  position: relative;
  top: 20px;
  z-index: 1;
}

.pattern img {
  max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Waves.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,WAAW;EACX,YAAY;;EAEZ,sBAAsB;EACtB,aAAa;EACb,gBAAgB;EAChB,oBAAoB;;EAEpB,SAAS;EACT,wBAAwB;EACxB,gBAAgB;EAChB,eAAe;;EAEf,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".wave {\n  /* size */\n  width: 100%;\n  height: 67px;\n\n  /* children position */\n  display: flex;\n  padding-top: 5px;\n  padding-bottom: 35px;\n\n  /* font */\n  font-family: \"Amatic SC\";\n  font-weight: 700;\n  font-size: 22pt;\n\n  box-sizing: content-box; \n}\n\n.wave > div {\n  display: flex;\n  justify-content: space-evenly;\n  width: 50%;\n}\n\n.wave a {\n  margin: 20px 0px;\n  height: 37px;\n}\n\n.footer {\n  max-width: 100%;\n  color: white;\n}\n\n.copyright {\n  width: 600px;\n  padding-right: 40px;\n}\n\n.pattern {\n  position: relative;\n  top: 20px;\n  z-index: 1;\n}\n\n.pattern img {\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
