import { Logo } from "../Components/Logo";
import "./Project.css"

export const HelloProjectInfo: React.FC<{}> = () => {
  return (
    <div className="project">
      <Logo />
      <div className="description">
        <p>
          Привет! Я – КОТОБОТ! Я буду помогать вам изучать информатику. Мы будем
          решать разные задачи и писать программы. Команды в моем языке простые,
          вы их легко освоите. Я буду послушно исполнять все задания, полагаясь
          на ваши знания и умения. А если в алгоритм закрадется ошибка,
          подскажу, где она. Составляя программы на моем языке, вы узнаете, что
          такое алгоритм, ветвление, цикл и многое другое. Желаю всем удачи и
          красивых программ!
        </p>
      </div>
    </div>
  );
};
