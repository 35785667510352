import styled, { css } from "styled-components";
import {
  ConteinerBg,
  StyledConteiner,
  StyledFlexConteiner,
} from "../../../../Components/StyledComponents/Containers";
import { StyledInputWithBorder } from "../../../../Components/StyledComponents/Input";
import { StyledLittleTitleH1 } from "../../../../Components/StyledComponents/Text";
import "./TaskListFilter.css";
import {
  TaskStatusEnum,
  allTaskStatuses,
  allTaskStatusesWithText,
} from "../../../../Enums/TaskStatuses";
import { Type } from "../../../../DTO/ServerResponseType/Type";
import { Tag } from "../../../../DTO/ServerResponseType/Tag";
import { FilterStore, dataFilterType } from "../../../../Stores/FilterStore";
import { observer } from "mobx-react";
import { useLoaderData } from "../../../../utils/route/UseLoaderData";
import TasksStore from "../../../../Stores/TasksStore";
import dictionary from "../../../../langs.json";

const StyledFilterItemsDiv = styled.div<{
  $max_height: string;
  $overflow_y: string;
}>`
  max-height: ${({ $max_height = "auto" }) => $max_height};
  margin: 5px;

  overflow-y: ${({ $overflow_y = "visible" }) => $overflow_y};
`;

const Filter: React.FC<{
  nameFilter: string;
  dataFilter: dataFilterType[];
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}> = ({ nameFilter, dataFilter, onChange }) => {
  let filterStore = new FilterStore(dataFilter);

  return (
    <div className="filter-checkbox-div">
      <p className="filter-head">{nameFilter}</p>
      <FilterCheckbox
        filterStore={filterStore}
        onChange={onChange}
      ></FilterCheckbox>
    </div>
  );
};

const FilterCheckbox: React.FC<{
  filterStore: FilterStore;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}> = observer(({ filterStore, onChange }) => {
  let condition: boolean = filterStore.data.length > 5;
  return (
    <>
      {condition && (
        <StyledInputWithBorder
          placeholder="Поиск"
          onChange={filterStore.onChangeSearchTextPattern}
        ></StyledInputWithBorder>
      )}
      <StyledFilterItemsDiv
        $max_height="200px"
        $overflow_y={condition ? "scroll" : "visible"}
      >
        {filterStore.searchedDataFilter.map((item: dataFilterType) => (
          <p className="filter-item">
            <input type="checkbox" value={item.value} onChange={onChange} />
            <label>{item.text}</label>
          </p>
        ))}
      </StyledFilterItemsDiv>
    </>
  );
});

export const TaskListFilter: React.FC<{}> = () => {
  let loadedData = useLoaderData<{
    allTaskTypes: Type[];
    allTaskTags: Tag[];
  }>();

  if (loadedData === null || loadedData === undefined) {
    throw Error();
  }

  let taskTypesDataFilter: dataFilterType[] = loadedData.allTaskTypes.map(
    (taskType: Type) => {
      return {
        value: taskType.taskTypeId,
        text: dictionary.ru.translation[
          taskType.typeName as keyof typeof dictionary.ru.translation
        ],
      };
    }
  );

  let taskTagsDataFilter: dataFilterType[] = loadedData.allTaskTags
    .sort((a, b) => a.tagName.localeCompare(b.tagName))
    .map((taskTag: Tag) => {
      return {
        value: taskTag.tagId,
        text: taskTag.tagName,
      };
    });

  let taskStatusesDataFilter: dataFilterType[] = allTaskStatuses.map(
    (status: TaskStatusEnum) => {
      let textOrUndefined: string | undefined =
        allTaskStatusesWithText.get(status);

      return {
        value: status.toString(),
        text: textOrUndefined ? textOrUndefined : "",
      };
    }
  );

  return (
    <StyledFlexConteiner className="filter">
      <div style={{ width: "1px", backgroundColor: ConteinerBg.green }}></div>
      <StyledConteiner>
        <StyledLittleTitleH1>Фильтрация</StyledLittleTitleH1>
        <Filter
          nameFilter="Статус"
          dataFilter={taskStatusesDataFilter}
          onChange={TasksStore.onChangeStatusFilter}
        ></Filter>
        <Filter
          nameFilter="Тип"
          dataFilter={taskTypesDataFilter}
          onChange={TasksStore.onChangeTypeFilter}
        ></Filter>
        <Filter
          nameFilter="Тег"
          dataFilter={taskTagsDataFilter}
          onChange={TasksStore.onChangeTagFilter}
        ></Filter>
      </StyledConteiner>
    </StyledFlexConteiner>
  );
};
