// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-button {
  display: block;
  font-family: "Manrope";
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 15px;
  border: 1px solid #1a6a7c;
  cursor: pointer;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15), 0 6px 40px 0 rgba(0, 0, 0, 0.15);
}

.default-button {
  background-color: #ffffff;
  color: #1a6a7c;
}

.primary-button {
  background-color: #1a6a7c;
  color: white;
}

button:hover {
  transform: scale(1.05);
  transition-duration: 200ms;
}
`, "",{"version":3,"sources":["webpack://./src/Components/StyledComponents/Buttons.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,yBAAyB;EACzB,eAAe;EACf,8EAA8E;AAChF;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,0BAA0B;AAC5B","sourcesContent":[".custom-button {\n  display: block;\n  font-family: \"Manrope\";\n  padding: 10px 20px;\n  border-radius: 30px;\n  font-size: 15px;\n  border: 1px solid #1a6a7c;\n  cursor: pointer;\n  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15), 0 6px 40px 0 rgba(0, 0, 0, 0.15);\n}\n\n.default-button {\n  background-color: #ffffff;\n  color: #1a6a7c;\n}\n\n.primary-button {\n  background-color: #1a6a7c;\n  color: white;\n}\n\nbutton:hover {\n  transform: scale(1.05);\n  transition-duration: 200ms;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
