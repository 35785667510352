// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("img/Phenomena-Light.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("img/AmaticSC-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("img/Manrope.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Phenoma Light";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Amatic SC";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Manrope";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2");
}

body {
  margin: 0;
  background-color: #dddddd;
  font-family: "Phenoma Light";
  font-size: 20px;
  color: #1a6a7c;
  box-sizing: content-box;
}

a {
  text-decoration: none;
  color: #1a6a7c;
}

.underline {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,2DAAkD;EAClD,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;EACxB,2DAAgD;EAChD,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,4DAA6C;AAC/C;;AAEA;EACE,SAAS;EACT,yBAAyB;EACzB,4BAA4B;EAC5B,eAAe;EACf,cAAc;EACd,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["@font-face {\n  font-family: \"Phenoma Light\";\n  src: url(\"img/Phenomena-Light.otf\") format(\"woff\");\n  font-weight: 400;\n}\n\n@font-face {\n  font-family: \"Amatic SC\";\n  src: url(\"img/AmaticSC-Bold.ttf\") format(\"woff\");\n  font-weight: 400;\n}\n\n@font-face {\n  font-family: \"Manrope\";\n  src: url(\"img/Manrope.woff2\") format(\"woff2\");\n}\n\nbody {\n  margin: 0;\n  background-color: #dddddd;\n  font-family: \"Phenoma Light\";\n  font-size: 20px;\n  color: #1a6a7c;\n  box-sizing: content-box;\n}\n\na {\n  text-decoration: none;\n  color: #1a6a7c;\n}\n\n.underline {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
