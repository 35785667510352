// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news-card {
  margin: 40px auto;
  width: 90%;
}

.newsData {
  margin: 20px;
}

.newsData > * {
  margin: 10px;
}

.newsPic {
  min-width: 30%;
  max-width: 30%;
  text-align: center;
  align-self: center;
}

.newsPic img {
  height: auto;
  width: 100%;
}

.newsButtons {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.newsButtons * {
  margin: 20px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/NewsWindows/NewsListWindow/LittleNews.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,cAAc;EACd,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".news-card {\n  margin: 40px auto;\n  width: 90%;\n}\n\n.newsData {\n  margin: 20px;\n}\n\n.newsData > * {\n  margin: 10px;\n}\n\n.newsPic {\n  min-width: 30%;\n  max-width: 30%;\n  text-align: center;\n  align-self: center;\n}\n\n.newsPic img {\n  height: auto;\n  width: 100%;\n}\n\n.newsButtons {\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.newsButtons * {\n  margin: 20px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
