import { styled } from "styled-components";
import { useState } from "react";
import { StyledFlexConteiner } from "../../../../../Components/StyledComponents/Containers";
import { LittleTaskCardApi } from "../../DTO/LittleTaskAPI";
import { CloseDefaultButton, ShowDefaultButton } from "../../../../../Components/IconButtons";

const StyledTaskTagsText = styled.p.attrs(() => ({
  className: "task-card-tags-p",
}))<{ white_space: string }>`
  white-space: ${({ white_space = "normal" }) => white_space};
`;

export const LittleTaskTags: React.FC<{ taskData: LittleTaskCardApi }> = ({
  taskData,
}) => {
  const [textTagWhiteSpace, setWhiteSpace] = useState("nowrap");

  return (
    <StyledFlexConteiner className="task-card-tags" jc="end" margin="10px auto">
      <StyledTaskTagsText white_space={textTagWhiteSpace}>
        {taskData.taskTags.map((tag) => tag.tagName).join(", ")}
      </StyledTaskTagsText>
      {textTagWhiteSpace == "nowrap" && (
        <ShowDefaultButton
          onClick={(event) => {
            event.preventDefault();
            setWhiteSpace("normal");
          }}
        ></ShowDefaultButton>
      )}
      {textTagWhiteSpace == "normal" && (
        <CloseDefaultButton
          onClick={(event) => {
            event.preventDefault();
            setWhiteSpace("nowrap");
          }}
        ></CloseDefaultButton>
      )}
    </StyledFlexConteiner>
  );
};
