import { useLoaderData } from "react-router-dom";
import { Content } from "../../Components/Content";
import { LittleNews, LittleNewsApi } from "./LittleNews";
import { StyledTitleH1 } from "../../Components/StyledComponents/Text";
import NewsStore from "../../Stores/NewsStore";
import { observer } from "mobx-react";
import { NewsImageResponse } from "../../DTO/ServerResponseType/NewsItem";

interface NewsPropApi {
  newsId: string;
  newsTitle: string;
  newsPreviewText: string;
  newsDate: string;
  newsImages: NewsImageResponse[];
}

export class NewsPropImpl implements NewsPropApi {
  newsId: string;
  newsTitle: string;
  newsPreviewText: string;
  newsDate: string;
  newsImages: NewsImageResponse[];

  constructor(newsData: {
    newsId: string;
    newsTitle: string;
    newsPreviewText: string;
    newsDate: string;
    newsImages: NewsImageResponse[];
  }) {
    this.newsId = newsData.newsId;
    this.newsTitle = newsData.newsTitle;
    this.newsPreviewText = newsData.newsPreviewText;
    this.newsDate = newsData.newsDate;
    this.newsImages = newsData.newsImages;
  }
}

export class NewsItemPropImpl extends NewsPropImpl {
  newsHtml: string;
  newsAuthor: string;

  constructor() {
    super({
      newsId: "",
      newsTitle: "",
      newsPreviewText: "",
      newsDate: "",
      newsImages: [],
    });
    this.newsHtml = "";
    this.newsAuthor = "";
  }
}

class LittleNewsImpl extends NewsPropImpl implements LittleNewsApi {
  newsImage: NewsImageResponse;
  constructor(newsData: NewsPropApi) {
    super(newsData);
    this.newsImage = newsData.newsImages[0];
  }
}

export const NewsList: React.FC<{
  isDebugMode?: boolean;
}> = observer((props) => {
  console.log("render little news");
  return (
    <>
      {NewsStore.news.map((one: NewsPropApi) => (
        <>
          <LittleNews
            newsData={new LittleNewsImpl(one)}
            isDebugMode={props.isDebugMode}
            newsStore={NewsStore}
          />
        </>
      ))}
    </>
  );
});

export const NewsListWindow = () => {
  console.log("render news");
  const loaderData: any = useLoaderData();
  console.log(loaderData);
  return (
    // Different content color and different wave
    <Content isBlack={true}>
      <StyledTitleH1>Новости</StyledTitleH1>
      <NewsList isDebugMode={false} />
    </Content>
  );
};
