import { useRef, useState } from "react";
import "./CatButton.css";
import { CSSTransition } from "react-transition-group";

interface CatButtonApi {
  href: string;
  img: { src: string };
  text: { header: string; body: string; footer: any };
}

export const CatButton: React.FC<CatButtonApi> = (props) => {
  const [inMoiseOver, setInMoiseOver] = useState(false);
  const nodeRef = useRef(null);
  return (
    <a
      href={props.href}
      onMouseOver={() => setInMoiseOver(true)}
      onMouseLeave={() => setInMoiseOver(false)}
    >
      <CSSTransition nodeRef={nodeRef} in={inMoiseOver} timeout={250}>
        <div ref={nodeRef} className="cat">
          <img src={props.img.src} />
          <h3>{props.text.header}</h3>
          <p>{props.text.body}</p>
          <p>
            <b> {props.text.footer} </b>
          </p>
        </div>
      </CSSTransition>
    </a>
  );
};
