// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cat {
  width: 320px;
  padding: 20px;
  margin: 0 15px 0 15px;
  text-align: center;
  border-radius: 7px;
  background-color: #f8f8f8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.082),
    0 6px 20px 0 rgba(0, 0, 0, 0.034);

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-sizing: border-box;
}

.enter {
  background-color: #f8f8f8;
}
.enter-active {
  background-color: white;
  box-shadow:0 8px 16px 0 rgba(0, 0, 0, 0.082),
  0 12px 40px 0 rgba(0, 0, 0, 0.034);
  transition: background-color 0.25s, box-shadow 0.25s;
}
.enter-done {
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.082),
  0 12px 40px 0 rgba(0, 0, 0, 0.034);
}
.exit {
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.082),
  0 12px 40px 0 rgba(0, 0, 0, 0.034);
}
.exit-active {
  background-color: #f8f8f8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.082),
    0 6px 20px 0 rgba(0, 0, 0, 0.034);
  transition: background-color 0.25s, box-shadow 0.25s;
}

.cat > h3 {
  font-family: "Amatic SC", cursive;
  font-size: 24pt;
  color: rgb(79, 167, 153);
  margin: 15px 0 15px 0;
  line-height: 0.9em;
}

.cat > p {
  margin: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/MainWindow/CatButton.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB;qCACmC;;EAEnC,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,uBAAuB;EACvB;oCACkC;EAClC,oDAAoD;AACtD;AACA;EACE,uBAAuB;EACvB;oCACkC;AACpC;AACA;EACE,uBAAuB;EACvB;oCACkC;AACpC;AACA;EACE,yBAAyB;EACzB;qCACmC;EACnC,oDAAoD;AACtD;;AAEA;EACE,iCAAiC;EACjC,eAAe;EACf,wBAAwB;EACxB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".cat {\n  width: 320px;\n  padding: 20px;\n  margin: 0 15px 0 15px;\n  text-align: center;\n  border-radius: 7px;\n  background-color: #f8f8f8;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.082),\n    0 6px 20px 0 rgba(0, 0, 0, 0.034);\n\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  box-sizing: border-box;\n}\n\n.enter {\n  background-color: #f8f8f8;\n}\n.enter-active {\n  background-color: white;\n  box-shadow:0 8px 16px 0 rgba(0, 0, 0, 0.082),\n  0 12px 40px 0 rgba(0, 0, 0, 0.034);\n  transition: background-color 0.25s, box-shadow 0.25s;\n}\n.enter-done {\n  background-color: white;\n  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.082),\n  0 12px 40px 0 rgba(0, 0, 0, 0.034);\n}\n.exit {\n  background-color: white;\n  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.082),\n  0 12px 40px 0 rgba(0, 0, 0, 0.034);\n}\n.exit-active {\n  background-color: #f8f8f8;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.082),\n    0 6px 20px 0 rgba(0, 0, 0, 0.034);\n  transition: background-color 0.25s, box-shadow 0.25s;\n}\n\n.cat > h3 {\n  font-family: \"Amatic SC\", cursive;\n  font-size: 24pt;\n  color: rgb(79, 167, 153);\n  margin: 15px 0 15px 0;\n  line-height: 0.9em;\n}\n\n.cat > p {\n  margin: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
