import { NavLink, useLoaderData } from "react-router-dom";
import { StyledFlexConteiner } from "../../Components/StyledComponents/Containers";
import { Content } from "../../Components/Content";
import {
  StyledPrimaryButton,
  StyledScrollButton,
} from "../../Components/StyledComponents/Buttons";
import "./NewsItemWindow.css";
import "./NewsHtml.css";
import {
  StyledDateSpan,
  StyledTextSpan,
} from "../../Components/StyledComponents/Span";
import {
  StyledLittleTitleH1,
  StyledTitleH1,
  StyledTitleH2,
} from "../../Components/StyledComponents/Text";
import { GalleryStore } from "../../Stores/GalleryStore";
import { observer } from "mobx-react";
import "../../Components/DialogModal";
import { FullNewsImage } from "../../Components/Image";
import { NewsItemResponse } from "../../DTO/ServerResponseType/NewsItem";

const OtherNewsItem: React.FC<{ newsItem: any }> = ({ newsItem }) => {
  return (
    <article className="other--news--block">
      <NavLink to={`/news/one/${newsItem.newsId}`}>
        <div className="other--news--image">
          <FullNewsImage newsImage={newsItem.newsImages[0]}></FullNewsImage>
        </div>
        <StyledFlexConteiner
          padding="15px"
          jc="space-between"
          flex_direction="column"
          align_items="center"
        >
          <StyledLittleTitleH1>{newsItem.newsTitle}</StyledLittleTitleH1>
          <div className="other--news--text">
            <p>{newsItem.newsPreviewText}</p>
          </div>
          <div className="other--news--button">
            <StyledPrimaryButton>Подробнее</StyledPrimaryButton>
          </div>
        </StyledFlexConteiner>
      </NavLink>
    </article>
  );
};

const ZoomImage: React.FC<{ galleryStore: GalleryStore }> = observer(
  ({ galleryStore }) => {
    return (
      <>
        <div className="zoom--img" onClick={galleryStore.zoomOut}>
          <StyledScrollButton
            onClick={(event) => {
              event.stopPropagation();
              galleryStore.changeImageIndex(
                (galleryStore.imageIndex + galleryStore.images.length - 1) %
                  galleryStore.images.length
              );
            }}
          >
            &lt;
          </StyledScrollButton>
          <div onClick={(event) => event.stopPropagation()}>
            <FullNewsImage
              newsImage={galleryStore.images[galleryStore.imageIndex]}
            />
          </div>
          <StyledScrollButton
            onClick={(event) => {
              event.stopPropagation();
              galleryStore.changeImageIndex(
                (galleryStore.imageIndex + 1) % galleryStore.images.length
              );
            }}
          >
            &gt;
          </StyledScrollButton>
        </div>
        <div className="modal--backdrop" onClick={galleryStore.zoomOut}></div>
      </>
    );
  }
);

const ImagesGallery: React.FC<{ galleryStore: GalleryStore }> = observer(
  ({ galleryStore }) => {
    return (
      <div className="picture--block">
        <FullNewsImage
          newsImage={galleryStore.images[galleryStore.imageIndex]}
          onClick={galleryStore.zoomIn}
        />
        <StyledFlexConteiner
          jc="space-between"
          align_items="center"
          width="75%"
        >
          <StyledScrollButton
            onClick={() => {
              galleryStore.changeImageIndex(
                (galleryStore.imageIndex + galleryStore.images.length - 1) %
                  galleryStore.images.length
              );
            }}
          >
            &lt;
          </StyledScrollButton>
          <p>
            {galleryStore.imageIndex + 1} из {galleryStore.images.length}
          </p>
          <StyledScrollButton
            onClick={() => {
              galleryStore.changeImageIndex(
                (galleryStore.imageIndex + 1) % galleryStore.images.length
              );
            }}
          >
            &gt;
          </StyledScrollButton>
        </StyledFlexConteiner>
      </div>
    );
  }
);

const ShowZoomImage: React.FC<{ galleryStore: GalleryStore }> = observer(
  ({ galleryStore }) => {
    return (
      <>
        {galleryStore.isZoomImage && <ZoomImage galleryStore={galleryStore} />}
      </>
    );
  }
);

export const NewsItemWindow: React.FC<{}> = () => {
  console.log("render news item");
  const newsData: any = useLoaderData();
  let newsItems: NewsItemResponse[] = newsData.slice();
  let tempNewItem: NewsItemResponse = newsItems[0];
  let otherNewsItems = newsItems.splice(1);

  let galleryStore = new GalleryStore(tempNewItem.newsImages);
  return (
    <Content isBlack={true}>
      <StyledTitleH1>{tempNewItem.newsTitle}</StyledTitleH1>
      <div className="news--block">
        <StyledFlexConteiner jc="space-between" width="75%" margin="0px auto">
          <StyledTextSpan>
            {tempNewItem.newsAuthor && `Автор: ${tempNewItem.newsAuthor}`}
          </StyledTextSpan>
          <StyledDateSpan>{tempNewItem.newsDate}</StyledDateSpan>
        </StyledFlexConteiner>
        <ImagesGallery galleryStore={galleryStore} />

        <div className="news--html">
          <div dangerouslySetInnerHTML={{ __html: tempNewItem.newsHtml }} />
        </div>
      </div>

      <StyledTitleH2>Другие новости</StyledTitleH2>
      <section>
        <StyledFlexConteiner jc="space-evenly" width="75%" margin="45px auto">
          {otherNewsItems.map((newsItem: NewsItemResponse) => (
            <OtherNewsItem
              key={newsItem.newsId}
              newsItem={newsItem}
            ></OtherNewsItem>
          ))}
        </StyledFlexConteiner>
      </section>
      <ShowZoomImage galleryStore={galleryStore} />
    </Content>
  );
};
