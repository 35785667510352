import {
  StyledScrollButton,
} from "../../../Components/StyledComponents/Buttons";
import "./NewsCreateWindow.css";
import { useLoaderData } from "react-router-dom";
import { StyledFileInput, StyledInputWithBorder, StyledInputWithBorderWithGrow } from "../../../Components/StyledComponents/Input";
import { StyledTextArea } from "../../../Components/StyledComponents/TextArea";
import {
  StyledDarkOver,
  StyledFlexConteiner,
  StyledInputConteiner,
} from "../../../Components/StyledComponents/Containers";
import { StyledLabel } from "../../../Components/StyledComponents/Label";
import { observer } from "mobx-react";
import { NewsImage, NewsItemStore } from "../../../Stores/NewsItemStore";
import { NewsItemPropImpl } from "../../../NewsWindows/NewsListWindow/NewsList";
import { StyledFullImg } from "../../../Components/StyledComponents/Image";
import React from "react";
import {
  StyledSubTitleH1,
  StyledTitleH2,
} from "../../../Components/StyledComponents/Text";
import { SaveWindowButton, SaveWindowTitle } from "../SaveWindowComponents";
import "../CreateWindow.css"

enum Sides {
  left,
  right,
}

let NewsImagePanel: React.FC<{ newsStore: NewsItemStore }> = observer(
  ({ newsStore }) => {
    let scrollClick = (side: Sides) => {
      let panelImages = document.getElementsByClassName("panel");
      if (panelImages) {
        let panelImage = panelImages.item(0);
        if (panelImage) {
          let clientWidth: number = panelImage.clientWidth;

          let shift = clientWidth / 3;
          switch (side) {
            case Sides.left:
              if (panelImage.scrollLeft > 0) {
                panelImage.scrollLeft -= shift;
              } else {
                panelImage.scrollLeft = panelImage.scrollWidth - clientWidth;
              }
              break;
            case Sides.right:
              if (
                panelImage.scrollLeft <
                panelImage.scrollWidth - clientWidth
              ) {
                panelImage.scrollLeft += shift;
              } else {
                panelImage.scrollLeft = 0;
              }
              break;
            default:
              break;
          }
        }
      }
    };

    return (
      <StyledFlexConteiner
        jc="space-between"
        align_items="center"
        height="250px"
        margin="10px"
      >
        <StyledScrollButton
          onClick={() => {
            scrollClick(Sides.left);
          }}
        >
          &lt;
        </StyledScrollButton>

        <div className="panel">
          {newsStore.newsImages.map((image: NewsImage) => (
            <div key={image.id} className="panel-item">
              <div className="img-div">
                <StyledFullImg src={image.src} />
              </div>
              <button
                onClick={() => {
                  newsStore.deleteImage(image.id);
                }}
              >
                Удалить
              </button>
            </div>
          ))}
        </div>
        <StyledScrollButton
          onClick={() => {
            scrollClick(Sides.right);
          }}
        >
          &gt;
        </StyledScrollButton>
      </StyledFlexConteiner>
    );
  }
);

let NewsImageGallery: React.FC<{ newsStore: NewsItemStore }> = observer(
  ({ newsStore }) => {
    console.log("render gallery");
    return (
      <div className="images-div">
        <StyledTitleH2>Галерея фотографий</StyledTitleH2>
        <NewsImagePanel newsStore={newsStore} />
        <StyledInputConteiner>
          <StyledFileInput type="file" onChange={newsStore.addImageFromFile} />
        </StyledInputConteiner>
        {!newsStore.newsAllowImages && (
          <StyledDarkOver>
            <StyledSubTitleH1>
              Для добавления изоборажений создайте новость
            </StyledSubTitleH1>
          </StyledDarkOver>
        )}
      </div>
    );
  }
);



export const NewsCreateWindow: React.FC<{}> = () => {
  let tempDate: string = new Date().toISOString().split("T")[0];

  // Set initial state
  let loaderData: any = useLoaderData();
  let newsItem = new NewsItemPropImpl();
  if (loaderData !== null && loaderData !== undefined) {
    newsItem = loaderData;
  } else newsItem.newsDate = tempDate;
  let newsStore = new NewsItemStore(newsItem);

  return (
    <div className="create-window">
      <SaveWindowTitle store={newsStore} />
      <StyledInputConteiner>
        <StyledLabel>Заголовок</StyledLabel>
        <StyledInputWithBorderWithGrow
          key={"newsTitle" + new Date().getMilliseconds()}
          type="text"
          onChange={newsStore.onChangeTitle}
          defaultValue={newsItem.newsTitle}
          required
        ></StyledInputWithBorderWithGrow>
      </StyledInputConteiner>
      <StyledInputConteiner>
        <StyledLabel>Превью</StyledLabel>
        <StyledTextArea
          key={"newsPreviewText" + new Date().getMilliseconds()}
          onChange={newsStore.onChangePreviewText}
          rows={10}
          defaultValue={newsItem.newsPreviewText}
          required
        ></StyledTextArea>
      </StyledInputConteiner>
      <StyledInputConteiner jc="start">
        <StyledLabel>Добавить превью в начало страницы</StyledLabel>
        <StyledInputWithBorder
          type="checkbox"
          onChange={newsStore.setIsAddPreviewTextToHtml}
        ></StyledInputWithBorder>
      </StyledInputConteiner>
      <StyledInputConteiner>
        <StyledLabel>Страница</StyledLabel>
        <StyledTextArea
          key={"newsHTML" + new Date().getMilliseconds()}
          onChange={newsStore.onChangeHtml}
          rows={15}
          defaultValue={newsItem.newsHtml}
          required
        ></StyledTextArea>
      </StyledInputConteiner>
      <StyledInputConteiner>
        <StyledLabel>Автор</StyledLabel>
        <StyledInputWithBorderWithGrow
          key={"newsAuthor" + new Date().getMilliseconds()}
          type="text"
          onChange={newsStore.onChangeAuthor}
          defaultValue={newsItem.newsAuthor}
        ></StyledInputWithBorderWithGrow>
      </StyledInputConteiner>
      <StyledInputConteiner>
        <StyledLabel>Дата</StyledLabel>
        <StyledInputWithBorder
          key={"newsDate" + new Date().getMilliseconds()}
          type="date"
          onChange={newsStore.onChangeDate}
          defaultValue={newsItem.newsDate}
          max={tempDate}
          required
        />
      </StyledInputConteiner>
      <SaveWindowButton store={newsStore} />
      <NewsImageGallery newsStore={newsStore} />
    </div>
  );
};
