import styled from "styled-components";
import { ConteinerBg } from "./Containers";

export const StyledInput = styled.input`
  margin: 5px;
  padding: 5px;
`;

export const StyledInputWithBorder = styled(StyledInput)`
  border: solid 1px;
  border-color: ${ConteinerBg.black};
  border-radius: 5px;
`;

export const StyledInputWithBorderWithGrow = styled(StyledInputWithBorder)`
  flex-grow: 1;
`;


export const StyledFileInput = styled(StyledInput)`
  font-size: 15px;
`;