import { TaskResponseApi } from "../../../../DTO/ServerResponseType/Task";
import { LittleTaskTableRowApi } from "./LittleTaskAPI";
import { LittleTaskDTO } from "./LittleTaskDTO";

export class LittleTaskTableRowDTO
  extends LittleTaskDTO
  implements LittleTaskTableRowApi
{
  taskCreationTime: string;
  taskModificationTime: string;
  constructor(taskData: TaskResponseApi) {
    super(taskData);
    this.taskCreationTime = taskData.taskCreationTime.toString();
    this.taskModificationTime = taskData.taskModificationTime.toString();
  }
}
