import { Sender, serverOrigin } from "./Configs";

class TagSender {
  private path = "/tag";

  sendToCreateTag = async (tagName: string): Promise<string> => {
    let tagId = await this.sendNewTag(tagName)
      .then((response) => {
        alert("Тег успешно создана.");
        let tagId = response.body.tagId;
        return tagId;
      })
      .catch((error) => {
        console.error("Ошибка:", error);
      });

    return tagId;
  };

  sendNewTag = async (tagName: string) => {
    let url: string = serverOrigin + this.path + "/create";

    let response = await Sender.sendRequest({
      url: url,
      method: "POST",
      body: JSON.stringify({ tagName: tagName }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response;
  };

  sendToDeleteTag = async (deletedTagId: string) => {
    let url: string = serverOrigin + this.path + "/delete";

    let response = await Sender.sendRequest({
      url: url,
      method: "POST",
      body: JSON.stringify({ tagId: deletedTagId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response;
  };
}

export default new TagSender();
