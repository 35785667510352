import { TaskIllustration } from "../../Stores/TaskStore";
import { Module } from "./Module";
import { Tag } from "./Tag";
import { Type } from "./Type";

export interface TaskPropApi {
  taskModificationTime: Date;
  taskCreationTime: Date;
  taskPrivateName: string;
  taskPublicName: string;
  taskTypeId: string;
  taskStatusId: number;
  taskContent: string | object;
  taskCost: number;
  taskTypeOfCheck: number;
  taskId?: string;
  taskTagsIds: string[];
  taskIllustrationId?: string;
}

export class TaskData implements TaskPropApi {
  taskPrivateName: string;
  taskPublicName: string;
  taskTypeId: string;
  taskStatusId: number;
  taskContent: string | object;
  taskCost: number;
  taskTypeOfCheck: number;
  taskId?: string;
  taskTagsIds: string[];
  taskIllustrationId?: string;
  taskModificationTime: Date;
  taskCreationTime: Date;

  constructor(taskData: TaskPropApi) {
    this.taskPrivateName = taskData.taskPrivateName;
    this.taskPublicName = taskData.taskPublicName;
    this.taskTypeId = taskData.taskTypeId;
    this.taskStatusId = taskData.taskStatusId;
    this.taskContent = taskData.taskContent;
    this.taskCost = taskData.taskCost;
    this.taskTypeOfCheck = taskData.taskTypeOfCheck;
    this.taskId = taskData.taskId;
    this.taskTagsIds = taskData.taskTagsIds;
    this.taskIllustrationId = taskData.taskIllustrationId;
    this.taskCreationTime = taskData.taskCreationTime;
    this.taskModificationTime = taskData.taskModificationTime;
  }
}

// Task to show and to send req for create and update tasks
export interface TaskShowApi extends TaskPropApi {
  taskIllustration?: TaskIllustration;
  taskTagsIds: string[];
  taskContent: string;
}

export class TaskShowDataFromResponse extends TaskData implements TaskShowApi {
  taskIllustration?: TaskIllustration;
  taskContent: string;

  constructor(taskData: TaskResponseApi) {
    super(taskData);
    if (
      taskData.taskIllustration !== undefined &&
      taskData.taskIllustration !== null
    ) {
      this.taskIllustration = {
        src: "data:image/png;base64," + taskData.taskIllustration.illustration,
        data: taskData.taskIllustration.illustration,
        author: taskData.taskIllustration.illustrationAuthor,
      };
    }

    this.taskContent = JSON.stringify(taskData.taskContent);
  }
}

export class StartTaskData implements TaskShowApi {
  taskModificationTime: Date = new Date();
  taskCreationTime: Date = new Date();
  taskIllustrationId?: string | undefined;
  taskId?: string = "";
  taskTagsIds: string[] = [];
  taskPrivateName: string = "";
  taskPublicName: string = "";
  taskTypeId: string = "";
  taskStatusId: number = 0;
  taskContent: string = "";
  taskCost: number = 0;
  taskTypeOfCheck: number = 0;
  taskIllustration?: TaskIllustration = undefined;
}

// Task from response
export type TaskIllustrationResponse = {
  illustrationId: string;
  illustration: string;
  illustrationAuthor?: string;
};

export interface TaskResponseApi extends TaskPropApi {
  taskModules: TaskModules[];
  taskModificationTime: Date;
  taskCreationTime: Date;
  taskTags: TaskTags[];
  taskContent: object;
  taskIllustration?: TaskIllustrationResponse | null;
  taskTagsIds: string[];
  taskType: Type;
}

export class TaskResponseData extends TaskData implements TaskResponseApi {
  taskContent!: object;
  taskIllustration?: TaskIllustrationResponse;
  taskModules: TaskModules[];
  taskTags: TaskTags[];
  taskType: Type;

  constructor(taskData: TaskResponseApi) {
    super(taskData);
    this.taskType = taskData.taskType;
    this.taskModules = taskData.taskModules;
    this.taskTags = taskData.taskTags;
  }
}

export type TaskDataToCreate = {
  types: Type[];
  tags: Tag[];
};

export type TaskModules = {
  module: Module;
};

export type TaskTags = {
  tag: Tag;
};
