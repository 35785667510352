import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { NewsImageResponse } from "../DTO/ServerResponseType/NewsItem";

export class GalleryStore {
  public images: IObservableArray<NewsImageResponse> = observable.array([]);
  public imageIndex: number = 0;
  public isZoomImage: boolean = false;

  constructor(images: NewsImageResponse[]) {
    makeAutoObservable(this);
    this.images.replace(images);
  }

  public changeImageIndex = (imageIndex: number) => {
    this.imageIndex = imageIndex;
  };

  public zoomIn = () => {
    this.isZoomImage = true;
  };

  public zoomOut = () => {
    this.isZoomImage = false;
  };
}
