import styled from "styled-components";

// Todo: theme
export const ConteinerBg = {
  none: "none",
  dust: "url(/img/dust_scratches.png)",
  white: "white",
  black: "#000",
  green: "#1a6a7c",
  light_green: "#4fa799",
};

interface StyledConteinerBasicProps {
  bg?: string;
  height?: string;
  width?: string;
  padding?: string;
  margin?: string;
  box_sizing?: string;
}

interface StyledConteinerFlexProps {
  jc?: string;
  align_items?: string;
  flex_direction?: string;
}

export const StyledConteiner = styled.div.attrs(() => ({
  className: "container",
}))<StyledConteinerBasicProps & StyledConteinerFlexProps>`
  background-image: ${({ bg = ConteinerBg.none }) => bg};
  background-color: ${({ bg = ConteinerBg.none }) => bg};
  height: ${({ height = "auto" }) => height};
  width: ${({ width = "auto" }) => width};
  padding: ${({ padding = "0px" }) => padding};
  margin: ${({ margin = "0px" }) => margin};
  box-sizing: ${({ box_sizing = "content-box" }) => box_sizing};
`;

export const StyledFlexConteiner = styled(
  StyledConteiner
)<StyledConteinerFlexProps>`
  display: flex;
  justify-content: ${({ jc = "center" }) => jc};
  align-items: ${({ align_items = "stretch" }) => align_items};
  flex-direction: ${({ flex_direction = "row" }) => flex_direction};
`;

export const StyledDarkOver = styled(StyledFlexConteiner)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 0.5;
  color: ${ConteinerBg.white};
  background-color: ${ConteinerBg.black};
  z-index: 50;
  align-items: center;
`;

export const StyledInputConteiner = styled(StyledFlexConteiner).attrs(() => ({
  jc: "start",
}))`
  margin: 5px auto;
  width: 90%;
`;
