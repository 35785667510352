import { observer } from "mobx-react";
import { TasksStoreApi } from "../../../../Stores/TasksStore";
import { DialogModal } from "../../../../Components/DialogModal";
import { StyledFlexConteiner } from "../../../../Components/StyledComponents/Containers";
import {
  StyledDefaultButton,
  StyledPrimaryButton,
} from "../../../../Components/StyledComponents/Buttons";

export const DeleteTaskModal: React.FC<{
  tasksStore: TasksStoreApi;
}> = observer(({ tasksStore }) => {
  return (
    <DialogModal onCloseClick={tasksStore.onDeleteModalClose}>
      <DialogModal.Header>Удаление задачи</DialogModal.Header>
      <DialogModal.Body>
        <p>Вы уверены, что хотите удалить задачу?</p>
        <p>Заголовок удаляемой задачи:</p>
        <h3> {tasksStore.deleteTaskData.taskPublicTitle} </h3>
      </DialogModal.Body>

      <DialogModal.Footer>
        <StyledFlexConteiner jc="space-evenly" width="100%">
          <StyledPrimaryButton
            onClick={() => {
              tasksStore.deleteTask(tasksStore.deleteTaskData.taskId);
              tasksStore.onDeleteModalClose();
            }}
          >
            Удалить
          </StyledPrimaryButton>
          <StyledDefaultButton onClick={tasksStore.onDeleteModalClose}>
            Отмена
          </StyledDefaultButton>
        </StyledFlexConteiner>
      </DialogModal.Footer>
    </DialogModal>
  );
});
