// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner {
  z-index: 2;
}

.banner > img {
  filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.4));
}
`, "",{"version":3,"sources":["webpack://./src/MainWindow/Banner.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EAEE,mDAAmD;AACrD","sourcesContent":[".banner {\n  z-index: 2;\n}\n\n.banner > img {\n  -webkit-filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.4));\n  filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.4));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
