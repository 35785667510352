import { StyledFlexConteiner } from "../../../../../Components/StyledComponents/Containers";
import { useNavigate } from "react-router-dom";
import TasksStore from "../../../../../Stores/TasksStore";
import { observer } from "mobx-react";
import { LittleTaskApi, LittleTaskCardApi } from "../../DTO/LittleTaskAPI";
import {
  DeleteDefaultButton,
  UpdateDefaultButton,
} from "../../../../../Components/IconButtons";

export const LittleTaskHead: React.FC<{
  taskData: LittleTaskCardApi;
}> = ({ taskData }) => {
  return (
    <>
      <StyledFlexConteiner jc="space-between" margin="5px 0px">
        <h1>{taskData.taskPublicTitle}</h1>
        <StyledFlexConteiner>
          <TaskButtonToUpdate taskId={taskData.taskId} />
          <TaskButtonToDelete taskData={taskData} />
        </StyledFlexConteiner>
      </StyledFlexConteiner>
      <h2>({taskData.taskPrivateTitle})</h2>
    </>
  );
};

const TaskButtonToUpdate: React.FC<{
  taskId: string;
}> = ({ taskId }) => {
  let navigate = useNavigate();
  return (
    <UpdateDefaultButton
      onClick={(event) => {
        event.preventDefault();
        navigate("../update/" + taskId, {
          relative: "path",
        });
      }}
    />
  );
};

const TaskButtonToDelete: React.FC<{
  taskData: LittleTaskApi;
}> = observer(({ taskData }) => {
  return (
    <DeleteDefaultButton
      onClick={(event) => {
        event.preventDefault();
        TasksStore.onDeleteModalOpen(taskData);
      }}
    />
  );
});
